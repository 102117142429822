import axios from "axios";
import { showErrorToast } from "../../utils/toastUtils";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiCreateQuote = async (data, location, status) => {
  data.quoteStatusId = status;
  data.locationLat = location.lat.toString();
  data.locationLng = location.lng.toString();
  if (data.otherAssetDetails === "") data.otherAssetDetails = null;
  if (data.otherAssetDetails !== null) {
    data.assetId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  }
  return axios
    .post(`/api/Quoting?tz=${timeZone}`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        const errorMessages = data.errors;

        // Count the total number of error messages
        const countTotalErrors = (errorMessages) => {
          return errorMessages.length;
        };

        // Get the total number of error messages
        const totalErrors = countTotalErrors(errorMessages);

        // Log the appropriate message
        if (totalErrors > 1) {
          showErrorToast("Please complete all required fields");
        } else if (totalErrors === 1) {
          // Log the first error message if there is only one error
          showErrorToast(errorMessages[0].errorDetails);
        } else {
          console.log("No errors found");
        }
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiUpdateQuote = async (data, location, status) => {
  const userName = localStorage.getItem("user");
  data.quoteStatusId = status;
  data.hoseMaker.map((item, i) => {
    if (item.step3_LFerrule === null) item.step3_LFerrule = 0;
    if (item.step5_RFerrule === null) item.step5_RFerrule = 0;
    if (item.positionId === null) item.positionId = 0;
    if (item.seqId === undefined) {
      const maxSeqId =
        data.hoseMaker.length > 1
          ? data.hoseMaker.reduce(
              (max, obj) => (obj.seqId > max ? obj.seqId : max),
              data.hoseMaker[0].seqId
            )
          : null;
      if (maxSeqId !== null) {
        item.seqId = maxSeqId + 1;
      } else {
        item.seqId = 0;
      }
    }
  });
  data.inventoryNonBOA?.map((item, i) => {
    item.seqId = i;
  });
  if (data.expiryDateTime) {
    var date = new Date(data.expiryDateTime);
    const formattedDate = date.toISOString();
    data.expiryDateTime = formattedDate;
  }
  if (data.otherAssetDetails === "") data.otherAssetDetails = null;
  if (data.otherAssetDetails !== null) {
    data.assetId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  }
  const payload = {
    companyName: data.companyName,
    contactName: data.contactName,
    contactNumber: data.contactNumber,
    contactEmail: data.contactEmail,
    customerPurchaseOrderNumber: data.customerPurchaseOrderNumber,
    customerLocation: data.customerLocation,
    locationLat: location.lat.toString(),
    locationLng: location.lng.toString(),
    urgencyId: data.urgencyId,
    assetSerialNumber: data.assetSerialNumber,
    assetId: data.assetId,
    otherAssetDetails: data.otherAssetDetails,
    assetPropValue: data.assetPropValue,
    assetPropUnit: data.assetPropUnit,
    quoteDesc: data.quoteDesc,
    quoteStatusId: status,
    distanceTravelled: data.distanceTravelled?.toString()
      ? Number(data.distanceTravelled)
      : null,
    hoursLabour: data.hoursLabour?.toString() ? Number(data.hoursLabour) : null,
    hoseMaker: data.hoseMaker,
    inventoryComponents: data.inventoryComponents,
    inventoryNonBOA: data.inventoryNonBOA,
    notes: data.notes,
    makeId: data.makeId,
    saveContactDetails: data.saveContactDetails ? true : false,
    customer: data.customer?.companyName
      ? data.customer
      : { id: null, companyName: data.companyName, userName: userName },
    customerContact: data.customerContact?.contactName
      ? data.customerContact
      : {
          id: null,
          customerId: null,
          contactName: data.contactName,
          contactNumber: data.contactNumber,
          email: data.contactEmail,
        },
    boahubLocationId: data.boahubLocationId,
    assetTextId: data.assetTextId,
    technicianEmail: data.technicianEmail,
    expiryDateTime: data.expiryDateTime,
  };
  if (data.selectCustomer && data.selectContact) {
    payload.saveContactDetails = false;
  }
  if (!data.selectCustomer) {
    payload.customer.id = null;
    payload.customerContact.id = null;
    payload.customerContact.customerId = null;
  }
  payload.hoseMaker.map((item, i) => {
    // Trim spaces for hose tags
    if (item.hoseIdTagging.length > 0) {
      item.hoseIdTagging.map((hoseTag, i) => {
        let trimmedStr = hoseTag.hoseId.replace(/\s/g, "");
        hoseTag.hoseId = trimmedStr;
        hoseTag.hoseName = item.hoseName;
        hoseTag.hoseOemNumber = item.hoseOemNumber;
        hoseTag.isHoseCleaned = item.isHoseCleaned;
        hoseTag.isVisuallyInspected = item.isVisuallyInspected;
        hoseTag.isDesignStandard = item.isDesignStandard;
      });
    }
  });
  return axios
    .put(`/api/Quoting/${data.id}?tz=${timeZone}`, payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiArchiveQuote = async (id) => {
  return axios.put(`/api/Quoting/archive/${id}`).then((result) => {
    if (result) {
      return result;
    }
    return false;
  });
};

export const apiRestoreQuote = async (id) => {
  return axios.put(`/api/Quoting/restore/${id}`).then((result) => {
    if (result) {
      return result;
    }
    return false;
  });
};

export const apiDeleteQuote = async (id) => {
  return axios.put(`/api/Quoting/delete/${id}`).then((result) => {
    if (result) {
      return result;
    }
    return false;
  });
};

export const apiGetQuotingList = (
  keyword,
  searchType,
  status,
  technicianFilter = "All",
  limit = 1000
) => {
  keyword = keyword !== "" ? keyword : null;
  if (technicianFilter === "") technicianFilter = "All";
  const data = {
    PageNo: 1,
    Limit: limit,
    NodaTZ: timeZone,
    SearchParam: keyword,
    QuoteStatusId: status !== 6 ? status : null,
    SearchType: searchType,
  };
  return axios.get(`/api/Quoting`, { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        if (status === 5) return result.data.records;
        let filteredData = result.data.records.filter(
          (obj) => obj.quoteStatusId !== 5
        );
        return filteredData;
      }
    }
    return false;
  });
};

export const apiGetQuoting = (id) => {
  const timeZoneURI = timeZone.toString().replace("/", "%2F");
  return axios.get(`/api/Quoting/${id}/${timeZoneURI}`).then((result) => {
    if (result && result.data) {
      return result.data.data;
    }
    return false;
  });
};

export const apiGeneratePDF = (quoteId, quoteNumber, type) => {
  const payload = {
    tz: timeZone,
  };
  let config = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
    params: payload,
  };
  return axios
    .get(`/api/Quoting/pdf/${quoteId}/${type}`, config)
    .then((response) => {
      if (response) {
        if (response.data && response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", quoteNumber + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
          return true;
        }
      }
      return false;
    });
};

export const apiSetTechnicianFilter = async (technician) => {
  const data = {
    defaultTechnician: technician,
  };
  return axios
    .put(`/api/Quoting/set-user-default-technician`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiSaveQuoteImage = async (data, id) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(`/api/Quoting/upload-quote-image?quoteId=${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiRemoveQuoteImage = async (imageId, id) => {
  return axios
    .delete(`/api/Quoting/delete-quote-image?quoteId=${id}&imageId=${imageId}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiDeleteAllHoseMakerImages = async (quoteId, hoseMakerSeqId) => {
  return axios
    .delete(
      `/api/Quoting/delete-hosemaker-alluploads?quoteId=${quoteId}&hoseMakerSeqId=${hoseMakerSeqId}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

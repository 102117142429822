import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Grid,
  Statistic,
  Icon,
  Step,
  Popup,
  Loader,
  Modal,
} from "semantic-ui-react";
import { eComFeatures, facilities } from "../../constants/appConstants";
const numeral = require("numeral");
import jobbing from "../../assets/images/pages/jobbing.png";
import settings from "../../assets/images/pages/settings.png";
import findBOAPod from "../../assets/images/pages/find-boapod.png";
import myAssets from "../../assets/images/pages/my-assets.png";
import products from "../../assets/images/pages/products.png";
import orderHistory from "../../assets/images/pages/order-history.png";
import myResources from "../../assets/images/pages/my-resources.png";
import boaHIS from "../../assets/images/pages/boa-his.png";
import inventory from "../../assets/images/pages/inventory.png";
import { useWindowSize } from "../../utils/utils";
import { HISpostData } from "../../containers/BOAhis/hisAPIprovider";
import jobManagement from "../../assets/images/pages/jobManagement.png";
import quoting from "../../assets/images/pages/quoting.png";

const images = [
  jobManagement,
  findBOAPod,
  myAssets,
  settings,
  products,
  orderHistory,
  myResources,
  boaHIS,
  inventory,
  quoting,
  jobbing,
];

const EnterpriseHomeComponent = ({ goToPage }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [HISusers, setHISusers] = useState(false);
  const [isJobbingOpen, setIsJobbingOpen] = useState(false);

  const size = useWindowSize();

  const checkHISpermission = async () => {
    const userEmail = localStorage.getItem("user");
    const response = await HISpostData("/his/users", { email: userEmail });
    if (response.ok) {
      setHISusers(true);
      const isAdmin = await response.json();
      localStorage.setItem("HISisAdmin", isAdmin);
    }
  };

  useEffect(() => {
    checkHISpermission();
    const screenWidth = localStorage.getItem("screenWidth");
    if (screenWidth) {
      const width = Number(screenWidth);
      if (width !== 0) {
        if (width <= 850) {
          if (window.location.pathname.includes("/home")) {
            setIsMobile(true);
            setIsLoaded(true);
          }
        } else {
          if (window.location.pathname.includes("/home")) {
            setIsMobile(false);
            setIsLoaded(true);
          }
        }
      }
    }
  }, []);

  const EcomFeatures = () => {
    const hasJobbing = localStorage.getItem("accessJobbing");
    const accountTypeData = localStorage.getItem("accountType");
    const accountType = Number(accountTypeData);
    const hasBoahubFindPod = localStorage.getItem("boahubFindPod");
    const isBoahubPremium = localStorage.getItem("isBoahubPremium");

    // Predetermine how many features to show and calculate size of tiles
    let featuresCount = eComFeatures.length;
    eComFeatures.map((feature) => {
      if (feature.title === "Job Management" && hasJobbing !== "true") {
        featuresCount -= 1;
      }
      if (feature.title === "My Orders" && accountType === 3) {
        featuresCount -= 1;
      }
      if (feature.title === "Products" && accountType === 3) {
        featuresCount -= 1;
      }
      if (feature.title === "Find BOApod" && hasBoahubFindPod !== "true") {
        featuresCount -= 1;
      }
      if (
        feature.title === "Inventory Management" &&
        isBoahubPremium !== "true"
      ) {
        featuresCount -= 1;
      }
    });
    const gridWidthSize = 16;
    const featureTileSize = Math.floor(gridWidthSize / featuresCount);

    return accountType === 0 ? (
      <div style={{ height: 216, padding: 60 }}>
        <Loader active className="workaround" size="large" inline="centered" />
      </div>
    ) : (
      <div>
        <Grid
          centered
          style={{
            paddingTop: 80,
            height: "100%",
            paddingLeft: !hasJobbing && !isMobile ? 260 : isMobile ? 0 : 200,
            paddingRight: isMobile ? 0 : 200,
            paddingBottom: 20,
          }}
        >
          {_.map(eComFeatures, (feature, key) => {
            const { title, page, image, active } = feature;

            // Checking for features to hide
            if (title === "Job Management" && hasJobbing !== "true") {
              return null;
            }
            if (title === "My Orders" && accountType === 3) {
              return null;
            }
            if (title === "Products" && accountType === 3) {
              return null;
            }
            if (title === "Find BOApod" && hasBoahubFindPod !== "true") {
              return null;
            }
            if (title === "Hydraulic Information System" && !HISusers) {
              return null;
            }
            if (
              title === "Inventory Management" &&
              isBoahubPremium !== "true"
            ) {
              return null;
            }
            return (
              <Grid.Column
                width={
                  isMobile
                    ? 8
                    : accountType === 1
                    ? 4
                    : featureTileSize > 2
                    ? featureTileSize
                    : 3
                }
                key={key}
                className="card-body"
                style={{ textAlign: "center", position: "relative" }}
              >
                {active ? (
                  <Step.Group
                    style={{
                      width: "100%",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      border: "1.5px solid rgb(102, 204, 35)",
                    }}
                  >
                    <Step
                      onClick={(e) => {
                        if (title === "Job Management") {
                          setIsJobbingOpen(true);
                        } else {
                          e.preventDefault();
                          if (e.ctrlKey) {
                            e.stopPropagation();
                            window.open(`/${page}`);
                            return;
                          }
                          goToPage(page);
                        }
                      }}
                    >
                      <img
                        src={images[key]}
                        style={{
                          height: isMobile ? 55 : "70px",
                          width: "auto",
                        }}
                      />
                    </Step>
                  </Step.Group>
                ) : (
                  <Popup
                    content="Coming Soon!"
                    position="top center"
                    trigger={
                      <Step.Group
                        style={{
                          width: "100%",
                          boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                          border: "1.5px solid rgb(102, 204, 35)",
                        }}
                      >
                        <Step
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={images[key]}
                            style={{
                              height: isMobile ? 55 : "70px",
                              width: "auto",
                            }}
                          />
                        </Step>
                      </Step.Group>
                    }
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    bottom: -5,
                    width: "100%",
                    paddingRight: 32,
                  }}
                >
                  <span style={{ fontSize: 20, color: "rgb(102, 204, 35)" }}>
                    {title}
                  </span>
                </div>
              </Grid.Column>
            );
          })}
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Modal size="tiny" open={isJobbingOpen} style={{ marginTop: -150 }}>
        <Modal.Header>JOB MANAGEMENT</Modal.Header>
        <Modal.Content>
          <Grid style={{ padding: 30 }}>
            <Grid.Column
              width={8}
              className="card-body"
              style={{ textAlign: "center", position: "relative" }}
            >
              <Step.Group
                style={{
                  width: "100%",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "1.5px solid rgb(102, 204, 35)",
                }}
              >
                <Step
                  onClick={(e) => {
                    e.preventDefault();
                    if (e.ctrlKey) {
                      e.stopPropagation();
                      window.open(`/quoting`);
                      return;
                    }
                    goToPage("quoting");
                  }}
                >
                  <img
                    src={images[8]}
                    style={{
                      height: isMobile ? 55 : "70px",
                      width: "auto",
                    }}
                  />
                </Step>
              </Step.Group>
              <div
                style={{
                  position: "absolute",
                  bottom: -5,
                  width: "100%",
                  paddingRight: 32,
                }}
              >
                <span style={{ fontSize: 20, color: "rgb(102, 204, 35)" }}>
                  Quoting
                </span>
              </div>
            </Grid.Column>
            <Grid.Column
              width={8}
              className="card-body"
              style={{ textAlign: "center", position: "relative" }}
            >
              <Step.Group
                style={{
                  width: "100%",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  border: "1.5px solid rgb(102, 204, 35)",
                }}
              >
                <Step
                  onClick={(e) => {
                    e.preventDefault();
                    if (e.ctrlKey) {
                      e.stopPropagation();
                      window.open(`/jobbing`);
                      return;
                    }
                    goToPage("jobbing");
                  }}
                >
                  <img
                    src={images[9]}
                    style={{
                      height: isMobile ? 55 : "70px",
                      width: "auto",
                    }}
                  />
                </Step>
              </Step.Group>
              <div
                style={{
                  position: "absolute",
                  bottom: -5,
                  width: "100%",
                  paddingRight: 32,
                }}
              >
                <span style={{ fontSize: 20, color: "rgb(102, 204, 35)" }}>
                  Jobbing
                </span>
              </div>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
      <Grid className="enterprise-home">
        <Grid.Column
          width={16}
          className="card-section"
          style={{
            paddingLeft: isMobile ? 0 : "1rem",
            paddingRight: isMobile ? 0 : "1rem",
          }}
        >
          {isLoaded ? <EcomFeatures /> : null}
        </Grid.Column>
      </Grid>
    </>
  );
};

EnterpriseHomeComponent.propTypes = {
  discountStatistics: PropTypes.object,
  goToPage: PropTypes.func,
  totalSavings: PropTypes.object,
  isFacility: PropTypes.bool,
  showAdvanceSavingsDetails: PropTypes.bool,
  account: PropTypes.object,
};
export default EnterpriseHomeComponent;

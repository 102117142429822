import React, { useCallback, useEffect, useState } from "react";
import "./quoting.css";
import {
  Card,
  Checkbox,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Button,
  Header,
  Select,
  TextArea,
  Popup,
  Container,
  Search,
  Modal,
} from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import Autocomplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import {
  hoseMakerTempData,
  quotingSearchDefaultOptions,
  quoteStatusList,
  tempData,
} from "./data";
import NumericInputBox from "../../components/NumericInputBox";
import productDefaultImage from "../../assets/images/product_default.png";
import noImageAvailable from "../../assets/images/no_image_available.png";
import SearchComponent from "../../containers/Search";
import AssetSearch from "./searchAsset";
import CustomerSearch from "./searchCustomer";
import ContactSearch from "./searchContact";
import { apiGetUsersListWithCurrent } from "../MyAssets/myAssets.api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateTime,
  padZero,
  sortKeysWithDatesFirst,
} from "../../utils/utils";
import QuoteImageUploader from "./ImageUploader";
import {
  apiAssetsSearch,
  apiCustomerList,
  apiGetHosePositions,
  apiGetUrgencies,
} from "../Jobbing/jobbing.api";
import {
  apiArchiveQuote,
  apiCreateQuote,
  apiDeleteAllHoseMakerImages,
  apiDeleteQuote,
  apiGeneratePDF,
  apiGetQuoting,
  apiGetQuotingList,
  apiRemoveQuoteImage,
  apiRestoreQuote,
  apiSaveQuoteImage,
  apiSetTechnicianFilter,
  apiUpdateQuote,
} from "./quoting.api";
import quotingIcon from "../../assets/images/pages/quoting.png";
import { apiGetStockLocationList } from "../Settings/settings.api";
import HoseMakerNew from "../HoseMakerNew";

const Quoting = (props) => {
  const [data, setData] = useState([]);
  const [quotingSearchOptions, setQuotingSearchOptions] = useState(
    quotingSearchDefaultOptions
  );
  const [quotingSearchKeyword, setQuotingSearchKeyword] = useState("");
  const [quotingSearchType, setQuotingSearchType] = useState(null);
  const [quotingSearchTypeFullName, setQuotingSearchTypeFullName] =
    useState(null);
  const [quoteListData, setQuoteListData] = useState([]);
  const [openHoseMaker, setOpenHoseMaker] = React.useState(false);
  const [openOtherComponents, setOpenOtherComponents] = React.useState(false);
  const [openCustomItem, setOpenCustomItem] = React.useState(false);
  const [openRemoveBOM, setOpenRemoveBOM] = React.useState(false);
  const [openToInvoiced, setOpenToInvoiced] = React.useState(false);
  const [openToInprogress, setOpenToInprogress] = React.useState(false);
  const [openToDuplicate, setOpenToDuplicate] = React.useState(false);
  const [openSendQuote, setOpenSendQuote] = React.useState(false);
  const [hoseMakerData, setHoseMakerData] = useState(hoseMakerTempData);
  const [hoseMakerIndex, setHoseMakerIndex] = useState(null);
  const [assetType, setAssetType] = useState("");
  const [assetImage, setAssetImage] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedData, setSelectedData] = useState(tempData);
  const [formData, setFormData] = useState(tempData);
  const [urgenciesData, setUrgenciesData] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [assetLoading, setAssetLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [hasLocation, setHasLocation] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [assetSearch, setAssetSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState(6);
  const [technicianFilter, setTechnicianFilter] = useState("");
  const [technicianName, setTechnicianName] = useState("");
  const [technicianList, setTechnicianList] = useState([]);
  const [technicianFilterList, setTechnicianFilterList] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [customProductText, setcCustomProductText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState({
    lat: -42.312144,
    lng: 172.474153,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [userCountryCode, setUserCountryCode] = useState("");
  const [countries, setCountries] = useState([]);

  //Customer
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [openIsDuplicateCustomer, setOpenIsDuplicateCustomer] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    if (selectedCustomer) {
      handleChange("customer", selectedCustomer);
    }
  }, [selectedCustomer]);

  //Contact
  const [selectedContact, setSelectedContact] = useState({});

  useEffect(() => {
    if (selectedContact) {
      handleChange("customerContact", selectedContact);
    }
  }, [selectedContact]);

  //Stock Location
  const [openSelectStockLocation, setOpenSelectStockLocation] = useState(false);
  const [openStockLocationError, setOpenStockLocationError] = useState(false);
  const [stockLocationError, setStockLocationError] = useState({});
  const [stocklocationList, setStocklocationList] = useState([]);

  //Hose Tagging
  const [isAssemble, setIsAssemble] = useState(false);

  //Quoting Image
  const [quoteImages, setQuoteImages] = useState([]);
  const [quoteAdditionalImages, setQuoteAdditionalImages] = useState([]);
  const [images, setImages] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);
  const [selectedFile, setSelectefFile] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isAdditionalViewerOpen, setIsAdditionalViewerOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [technicianDeleted, setTechnicianDeleted] = useState(false);

  const [openSearchList, setOpenSearchList] = useState(false);

  const handleQuotingSearchChange = (e) => {
    setOpenSearchList(true);
    if (e.target.value === "" && quotingSearchType !== null) {
      setQuotingSearchOptions(quotingSearchDefaultOptions);
      setQuotingSearchType(null);
      setQuotingSearchTypeFullName(null);
      onSearch("", null, filterStatus, technicianFilter);
      setOpenSearchList(false);
    }
    const updatedOptions = quotingSearchDefaultOptions?.map((item) => ({
      ...item,
      value: (
        <span
          style={{
            color: "#grey",
            fontWeight: "normal",
            fontSize: 16,
          }}
        >
          Search by {item.value}:{" "}
          <span
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: 16,
            }}
          >
            {e.target.value}
          </span>
        </span>
      ),
    }));
    setQuotingSearchKeyword(e.target.value);
    setQuotingSearchOptions(updatedOptions);
  };

  const handleQuotingResultSelect = (e, { result }) => {
    const _type = result.key;
    const _typeFullName = result.title.props.children[1];
    setOpenSearchList(false);
    setQuotingSearchType(_type);
    setQuotingSearchTypeFullName("Search by " + _typeFullName);
    onSearch(quotingSearchKeyword, _type, filterStatus, technicianFilter);
  };

  const onHandleKeyPress = (e) => {
    if (e.key === "Enter" && quotingSearchOptions.length > 0) {
      e.preventDefault(); // Prevent default Enter key behavior
      const _type = "All";
      const _typeFullName = "All";

      setOpenSearchList(false);

      setQuotingSearchType(_type);
      setQuotingSearchTypeFullName("Search by " + _typeFullName);
      onSearch(quotingSearchKeyword, _type, filterStatus, technicianFilter);
    }
  };

  const [selectedRS23Index, setSelectedRS23Index] = useState(0);
  const [openRS23Price, setOpenRS23Price] = useState(false);
  const [rs23Price, setRS23Price] = useState(0);
  const [rs23PriceTitle, setRS23PriceTitle] = useState("");

  const changeRS23priceHandler = (e, hoseMakerIndex, unitPrice) => {
    setOpenRS23Price(true);
    setRS23Price(unitPrice);
    setRS23PriceTitle(`SET RS23 PRICE FOR HOSE ITEM #${hoseMakerIndex + 1}`);
    setSelectedRS23Index(hoseMakerIndex);
    e.stopPropagation();
  };

  const closeRS23PriceForm = () => {
    setOpenRS23Price(false);
    onAutoSaveQuote(formData);
  };

  // callback function to delete the hose maker item
  const [openDeleteHoseMakerPopup, setOpenDeleteHoseMakerPopup] =
    useState(false);

  const onHandleDeleteHoseMaker = async () => {
    setIsLoading(true);
    var result = await apiDeleteAllHoseMakerImages(
      formData.id,
      formData.hoseMaker[hoseMakerIndex].seqId
    );
    if (result) {
      formData.hoseMaker.splice(hoseMakerIndex, 1);
      setFormData(formData);
      onAutoSaveQuote(formData);
      showSuccessToast(
        <div>
          <i className="check icon" /> Hose Maker Item Deleted Successfully
        </div>
      );
    } else {
      showErrorToast("Failed to delete Hose Maker Item");
    }
    setOpenDeleteHoseMakerPopup(false);
    setIsLoading(false);
  };

  // Function to format time as "HH:MM:SS"
  const formatTime = (totalSeconds) => {
    if (!totalSeconds) return "00:00:00";
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");

    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  };

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
    } else {
      setStocklocationList([]);
    }
  };

  const mileage =
    localStorage.getItem("userCountryCode") === "US" ? "mi" : "km";

  // Unit options for hours/mileage
  const options = [
    { key: "hrs", text: "hrs", value: "hrs" },
    { key: mileage, text: mileage, value: mileage },
  ];

  const locationRef = useRef(null);
  const ref = useRef();
  const { events } = useDraggable(ref);

  function handleChange(dataType, value) {
    const userName = localStorage.getItem("user");
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      if (i == 0) {
        if (dataType == "technicianEmail") {
          item.technicianSelected = true;
        }
        if (dataType == "expiryDateTime") {
          var date = new Date(value);
          const formattedDate = date.toISOString();
          item.expiryDateTime = formattedDate;
          setSelectedDate(value);
          return item;
        }
        if (dataType == "companyName") {
          item.customer = {};
          item.customer.companyName = value;
          item.customer.userName = userName;
          item.customer.id = null;
        }
        if (dataType == "discountPercent") {
          item.customer = item.customer ? item.customer : {};
          item.customer.discountPercent = value;
        }
        if (dataType == "contactName") {
          item.customerContact = {};
          item.customerContact.contactName = value;
          item.customerContact.userName = userName;
          item.customerContact.id = null;
          item.customerContact.customerId = item.customer?.id
            ? item.customer?.id
            : null;
        }
        if (dataType == "customer") {
          item.companyName = value.companyName;
          item.discountPercent = value.discountPercent;
        }
        if (dataType == "customerContact") {
          item.contactName = value.contactName;
          item.contactNumber = value.contactNumber;
          item.contactEmail = value.email;
        }
        if (dataType == "selectCustomer" && value == true) {
          if (!item.contactName) item.selectContact = true;
        }
        if (dataType == "makeId" && value == 0) {
          setAssetSearch("");
          setAssetType("");
          item.equipmentTypeId = null;
          item.assetId = null;
          setAssetImage("");
          setLocationList([]);
        }
        if (dataType == "makeId" && value != 0) {
          item.equipmentTypeId = null;
          item.assetId = null;
          item.otherAssetDetails = null;
          item.makeName = "";
          item.equipmentTypeName = "";
          item.model = "";
          setAssetType("");
          setAssetImage("");
          setLocationList([]);
        }
        if (dataType == "asset" && value) {
          item.assetId = value.id;
          item.otherAssetDetails = null;
          return item;
        }
        if (dataType == "customerLocation") {
          if (locationRef && locationRef.current)
            return {
              ...item,
              [dataType]: locationRef.current.refs.input.value,
            };
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    if (dataType === "assetId") {
      onAutoSaveQuote(quoteData[0]);
    }
    setFormData(quoteData[0]);
  }

  function handleInvoiceChange(dataType, value) {
    let newState = [];
    newState.push(invoice);
    let invoiceData = newState?.map((item, i) => {
      if (i == 0) {
        if (dataType === "contactId") {
          item.invoiceID = null;
          item.invoiceStatus = 1;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setInvoice(invoiceData[0]);
  }

  const addOtherComponents = async (product) => {
    var currentDate = new Date();
    var utcDate = currentDate.toISOString();
    const productData = {
      productId: product.id,
      quantity: 1,
      stockCode: product.stockCode,
      stockDescription: product.stockDescription,
      image128: product.stockImage1,
      name: product.stockDescription,
      barcode: product.stockCode,
      createdDateTime: utcDate,
    };

    let newState = formData.inventoryComponents
      ? formData.inventoryComponents
      : [];
    const existing = newState.find(
      (element) => element.productId === product.id
    );

    if (!existing) {
      newState.push(productData);
    } else {
      let invComponentsNewState = newState?.map((item, i) => {
        if (item.productId === product.id) {
          item.quantity += 1;
        }
        return item;
      });
      handleChange("inventoryComponents", invComponentsNewState);
      setOpenOtherComponents(false);
      return true;
    }

    // Sort inventory components AFTER adding product
    newState.sort(function (a, b) {
      return new Date(b.createdDateTime) - new Date(a.createdDateTime);
    });

    handleChange("inventoryComponents", newState);
    setOpenOtherComponents(false);
  };

  const addCustomComponents = async () => {
    const productData = {
      productId: null,
      stockCode: null,
      stockDescription: customProductText,
      image128: null,
      name: customProductText,
      barcode: null,
      description: customProductText,
      quantity: 1,
      sellPrice: 0,
    };

    let newState = formData.inventoryNonBOA ? formData.inventoryNonBOA : [];
    newState.push(productData);

    handleChange("inventoryNonBOA", newState);
    setOpenCustomItem(false);
  };

  function handleOtherComponentQuantityChange(index, value) {
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      if (i == 0) {
        item.inventoryComponents[index].quantity = value;
      }
      if (value == 0) {
        item.inventoryComponents.splice(index, 1);
      }
      return item;
    });

    handleChange("inventoryComponents", quoteData[0].inventoryComponents);
  }

  function handleCustomComponentQuantityChange(index, value) {
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      if (i == 0) {
        item.inventoryNonBOA[index].quantity = value;
      }
      if (value === -1) {
        item.inventoryNonBOA.splice(index, 1);
      }
      return item;
    });

    handleChange("inventoryNonBOA", quoteData[0].inventoryNonBOA);
  }

  function handleCustomComponentPriceChange(index, value) {
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      if (i == 0) {
        item.inventoryNonBOA[index].sellPrice = value;
      }
      return item;
    });

    handleChange("inventoryNonBOA", quoteData[0].inventoryNonBOA);
  }

  function handleBOMQuantityChange(index, value) {
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      if (i == 0) {
        item.hoseMaker[index].quantity = value;
      }
      if (value == 0) {
        item.hoseMaker.splice(index, 1);
      }
      return item;
    });

    handleChange("hoseMaker", quoteData[0].hoseMaker);
  }

  const addBOM = (data) => {
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      if (i == 0) {
        item.hoseMaker.push(data);
        return item;
      }
      return item;
    });

    onAutoSaveQuote(quoteData[0]);
    setFormData(quoteData[0]);
  };

  const updateBOM = (data, index) => {
    let newState = [];
    newState.push(formData);
    let quoteData = newState?.map((item, i) => {
      return item;
    });

    quoteData[0].hoseMaker?.map((item, i) => {
      if (index === i) {
        quoteData[0].hoseMaker[i] = data;
      }
    });

    onAutoSaveQuote(quoteData[0]);
    setFormData(quoteData[0]);
  };

  const onCancelHoseMaker = () => {
    setOpenHoseMaker(false);
  };

  const onSearch = async (keyword, searchType, status, technician) => {
    setSearchLoading(true);
    const result = await apiGetQuotingList(
      keyword,
      searchType,
      status,
      technician
    );
    if (result) {
      setSearchLoading(false);
      setData(result);
      setQuoteListData(result);
      setSearchLoading(false);
    }
  };

  const onSubmitQuote = async (status, progress) => {
    if (formData.id === "") {
      onSubmitCreateQuote(status, progress);
    } else {
      onSubmitUpdateQuote(status, progress);
    }
  };

  const onDuplicateQuote = async () => {
    setIsLoading(true);
    formData.customerPurchaseOrderNumber = "";
    formData.urgencyId = 0;
    formData.quoteDesc = "";
    formData.distanceTravelled = null;
    formData.hoursLabour = null;
    formData.notes = null;
    formData.saveContactDetails = false;
    formData.technicianEmail = null;
    formData.expiryDateTime = null;

    for (let i = 0; i < formData.hoseMaker.length; i++) {
      formData.hoseMaker[i].quantity = 1;

      // Reset BOM Assembly object, minimum 5 items required
      formData.hoseMaker[i].bomAssembly.lfActualCrimpSize = 0;
      formData.hoseMaker[i].bomAssembly.rfActualCrimpSize = 0;
      formData.hoseMaker[i].bomAssembly.causeType = null;
      formData.hoseMaker[i].bomAssembly.damageType = null;
      formData.hoseMaker[i].bomAssembly.hoseReplacementType = null;
      formData.hoseMaker[i].bomAssembly.isMarkedPushedOnCorrectly = false;
      formData.hoseMaker[i].bomAssembly.createdBy = null;
      formData.hoseMaker[i].bomAssembly.createdDateTime = null;
      formData.hoseMaker[i].bomAssembly.lastUpdatedDateTime =
        new Date().toISOString();

      formData.hoseMaker[i].isRS23Compliant = null;
      formData.hoseMaker[i].rS23ComplianceRate = null;
      formData.hoseMaker[i].hosePositionName = null;
      formData.hoseMaker[i].hosePositionImage = null;
      formData.hoseMaker[i].bomAssemblyImages = [];
      formData.hoseMaker[i].isHoseCleaned = null;
      formData.hoseMaker[i].isVisuallyInspected = null;
      formData.hoseMaker[i].isDesignStandard = null;
      formData.hoseMaker[i].hoseIdTagging = [{ hoseId: "", hoseIdSequence: 0 }];
    }

    const quoteData = await apiCreateQuote(formData, location, 2);
    if (quoteData) {
      formData.id = quoteData.id;
      const updatedQuoteData = await apiUpdateQuote(formData, location, 2);
      getQuotingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Quote Duplicated Successfully
        </div>
      );
      setSelectedId(quoteData.id);
      setSelectedCard(quoteData?.quoteDetails?.quoteNumber);
    }
    setIsLoading(false);
    setOpenToDuplicate(false);
  };

  const onSubmitCreateQuote = async (status, progress) => {
    if (formData.makeId === 0) {
      if (
        formData.makeName === "" ||
        formData.equipmentTypeName === "" ||
        formData.model === ""
      ) {
        showErrorToast("Please complete all required fields");
        if (progress) handleChange("quoteStatusId", status - 1);
        return false;
      } else {
        formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
      }
    }
    try {
      const quoteData = await apiCreateQuote(formData, location, status);
      if (quoteData) {
        getQuotingListData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Quote Created Successfully
          </div>
        );
        setSelectedId(quoteData.id);
        setSelectedCard(quoteData?.quoteDetails?.quoteNumber);
      } else {
        if (progress) handleChange("quoteStatusId", status - 1);
      }
    } catch {
      showErrorToast("Please complete all required fields");
      if (progress) handleChange("quoteStatusId", status - 1);
    }
  };

  const onSubmitUpdateQuote = async (status, progress) => {
    setIsLoading(true);
    if (status > 2 && formData.makeId === 0) {
      if (
        formData.makeName === "" ||
        formData.equipmentTypeName === "" ||
        formData.model === ""
      ) {
        showErrorToast("Please complete all required fields");
        if (progress) handleChange("quoteStatusId", status - 1);
        setIsLoading(false);
        return false;
      } else {
        formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
      }
    }
    if (formData.makeId === 0) {
      formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
    }
    const quoteData = await apiUpdateQuote(formData, location, status);
    if (quoteData) {
      if (quoteData.errors) {
        setStockLocationError(quoteData);
        setOpenStockLocationError(true);
        setIsLoading(false);
        if (progress) handleChange("quoteStatusId", status - 1);
      }
      if (quoteData.statusCode === 403) {
        setOpenSelectStockLocation(true);
        setIsLoading(false);
        return false;
      }

      setIsLoading(false);
      setOpenSelectStockLocation(false);
      setOpenToInprogress(false);
      setOpenToInvoiced(false);
      getQuotingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Quote Updated Successfully
        </div>
      );
      if (quoteData.duplicateCustomerMessage) {
        setDuplicateMessage(quoteData.duplicateCustomerMessage);
        setOpenIsDuplicateCustomer(true);
      }
      setTimeout(function () {
        getQuotingData();
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Please complete all required fields");
      if (progress) handleChange("quoteStatusId", status - 1);
    }
  };

  const onAutoSaveQuote = async (data) => {
    if (formData.makeId === 0) {
      if (
        formData.makeName === "" ||
        formData.equipmentTypeName === "" ||
        formData.model === ""
      ) {
      } else {
        formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
      }
    }
    try {
      const quoteData = await apiUpdateQuote(
        data,
        location,
        data.quoteStatusId
      );
      if (quoteData) {
        setOpenToInprogress(false);
        setOpenToInvoiced(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> Changes Saved Successfully
          </div>
        );
        setTimeout(function () {
          getQuotingData();
        }, 300);
      } else {
        // showErrorToast("Changes not saved");
      }
    } catch {
      // showErrorToast("Changes not saved");
    }
  };

  const onGeneratePDF = async (type) => {
    setIsLoading(true);
    try {
      const pdfData = await apiGeneratePDF(
        formData.id,
        selectedData?.quoteDetails?.quoteNumber,
        type
      );
      if (pdfData) {
        setIsLoading(false);
        setOpenSendQuote(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> PDF Downloaded Successfully
          </div>
        );
      } else {
        setIsLoading(false);
        setOpenSendQuote(false);
        showWarningToast("Generating PDF. Please come back later.");
      }
    } catch {
      setIsLoading(false);
      setOpenSendQuote(false);
      showErrorToast("Generate PDF Failed");
    }
  };

  const onArchiveQuote = async () => {
    const quoteData = await apiArchiveQuote(formData.id);
    if (quoteData) {
      getQuotingData();
      getQuotingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Quote Archived Successfully
        </div>
      );
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onRestoreQuote = async () => {
    const quoteData = await apiRestoreQuote(formData.id);
    if (quoteData) {
      getQuotingData();
      getQuotingListData();
      showSuccessToast(
        <div>
          <i className="check icon" /> Quote Restored Successfully
        </div>
      );
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onDeleteQuote = async () => {
    const quoteData = await apiDeleteQuote(formData.id);
    if (quoteData) {
      getQuotingListData();
      onNewQuote();
      showSuccessToast(
        <div>
          <i className="check icon" /> Quote Deleted Successfully
        </div>
      );
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onNewQuote = () => {
    const newData = {
      id: "",
      quoteNumber: "",
      companyName: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
      customerPurchaseOrderNumber: "",
      customerLocation: "",
      locationLat: null,
      locationLng: null,
      urgencyId: null,
      assetTextId: "",
      assetSerialNumber: "",
      makeId: null,
      equipmentTypeId: null,
      assetId: null,
      otherAssetDetails: null,
      assetPropValue: "",
      assetPropUnit: "hrs",
      quoteDesc: "",
      quoteImageDetails: null,
      quoteStatusId: 1,
      distanceTravelled: null,
      hoursLabour: null,
      hoseMaker: [],
      makeName: "",
      equipmentTypeName: "",
      model: "",
      saveContactDetails: true,
      customer: {},
      customerContact: {},
      selectCustomer: true,
      selectContact: true,
      expiryDateTime: null,
    };
    setSelectedDate(null);
    setSelectedId("");
    setSelectedData(newData);
    setFormData(newData);
    setSelectedCard("");
    setHasLocation(false);
    setAssetSearch("");
    setAssetType("");
    setAssetImage("");
    if (locationRef && locationRef.current)
      locationRef.current.refs.input.value = "";
    const geolocation = {
      lat: -42.312144,
      lng: 172.474153,
    };
    setLocation(geolocation);
    setHasLocation(false);
  };

  const onAddressSelection = (place) => {
    handleChange("customerLocation", locationRef.current.refs.input.value);
    if (place && place.geometry) {
      const geolocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLocation(geolocation);
      setHasLocation(true);
    }
  };

  const openGoogleMaps = () => {
    if (formData?.customerLocation === "") {
      showErrorToast("Enter location first");
      return false;
    }
    const googleMapsURL = `https://www.google.com/maps?q=${formData?.customerLocation}`;

    window.open(googleMapsURL, "_blank");
  };

  const getQuotingListData = async () => {
    onSearch(
      quotingSearchKeyword,
      quotingSearchType,
      filterStatus,
      technicianFilter
    );
  };

  const getQuotingData = async () => {
    setDataLoading(true);
    const result = await apiGetQuoting(selectedId);
    if (result) {
      if (!result.hoseMaker) result.hoseMaker = [];
      setSelectedData(result);
      setDataLoading(false);
    } else {
      showErrorToast("Failed to load the quote card, please try again");
      setDataLoading(false);
    }
  };

  const getImagesData = async () => {
    setDataLoading(true);
    const result = await apiGetQuoting(selectedId);
    if (result) {
      setQuoteImages(result.quoteAssetImages);
      setQuoteAdditionalImages(result.quoteAdditionalImages);
      let imagesFromData = [];
      let additionalImagesFromData = [];
      result.quoteAssetImages?.map((item, i) => {
        imagesFromData.push(item.filePath);
      });
      result.quoteAdditionalImages?.map((item, i) => {
        additionalImagesFromData.push(item.filePath);
      });
      setImages(imagesFromData);
      setAdditionalImages(additionalImagesFromData);
      setDataLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDataLoading(false);
    }
  };

  const getDropdownData = async () => {
    const urgencies = await apiGetUrgencies();
    if (urgencies) {
      setUrgenciesData(urgencies);
    }
  };

  const getCustomersData = async () => {
    const customers = await apiCustomerList();
    if (customers) {
      setCustomerList(customers);
    }
  };

  const getUsersList = async () => {
    const result = await apiGetUsersListWithCurrent();
    if (result) {
      const list = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      const filterList = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      setTechnicianList(list);
      filterList.unshift({ text: "Unassigned", value: "Unassigned" });
      filterList.unshift({ text: "All", value: "All" });
      setTechnicianFilterList(filterList);
    }
  };

  const getHosePositions = async () => {
    const hosePositions = await apiGetHosePositions(assetType);
    if (hosePositions) {
      setAssetImage(hosePositions.url);
      setLocationList(hosePositions.positions);
    } else {
      setAssetImage("");
      setLocationList([]);
    }
  };

  const getPositionName = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) return position.name ? position.name : "";
    }
    return "NO LOCATION ASSIGNED";
  };

  const getPositionImage = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) return position.url ? position.url : noImageAvailable;
    }
    return noImageAvailable;
  };

  const handleResultSelect = (e, { result }) => {
    setAssetLoading(true);
    const fullDescription = result.displayName.split(" | ");
    formData.makeName = fullDescription[0];
    formData.equipmentTypeName = fullDescription[1];
    formData.model = fullDescription[2];
    setAssetType(fullDescription[1]);
    handleChange("asset", result);
    setTimeout(function () {
      setAssetLoading(false);
    }, 500);
  };

  const handleAssetSearch = async (e, { value }) => {
    setAssetSearch(value);
  };

  const handleOpenAssetDetails = () => {
    setClicked(!clicked);
  };

  const getAssetList = async (search) => {
    setAssetLoading(true);
    setAssetList([]);
    if (search === "") {
      setAssetList([]);
      setAssetLoading(false);
      return false;
    }
    const assetsData = await apiAssetsSearch(search);
    if (assetsData) {
      setAssetList(assetsData);
      setAssetLoading(false);
    } else {
      setAssetLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAssetList(assetSearch);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [assetSearch]);

  useEffect(() => {
    //Get User's country data and country list
    const countryCode = localStorage.getItem("userCountryCode");
    setUserCountryCode(countryCode);
    const countryList = JSON.parse(localStorage.getItem("countries"));
    setCountries(countryList);

    const defaultTechnicianFilter = localStorage.getItem(
      "defaultTechnicianFilter"
    );
    setTechnicianFilter(defaultTechnicianFilter);

    const delayDebounceFn = setTimeout(() => {
      getDropdownData();
      getStockLocationsData();
      getCustomersData();
      getUsersList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    if (locationRef && locationRef.current)
      locationRef.current.refs.input.value = "";
    if (selectedData && selectedData.id != "") {
      setAssetSearch("");
      setCurrentStatus(selectedData.quoteDetails.quoteStatusId);
      setAssetType(
        selectedData.assetDetails.equipmentTypeName
          ? selectedData.assetDetails.equipmentTypeName
          : ""
      );
      if (
        selectedData.assetDetails?.otherAssetDetails !== "" &&
        selectedData.assetDetails?.otherAssetDetails !== null
      ) {
        const fullDescription =
          selectedData.assetDetails.otherAssetDetails.split(" | ");
        selectedData.makeName = fullDescription[0] ? fullDescription[0] : "";
        selectedData.equipmentTypeName = fullDescription[1]
          ? fullDescription[1]
          : "";
        selectedData.model = fullDescription[2] ? fullDescription[2] : "";
      } else {
        selectedData.makeName = selectedData.assetDetails?.makeName;
        selectedData.equipmentTypeName =
          selectedData.assetDetails?.equipmentTypeName;
        selectedData.model = selectedData.assetDetails?.model;
      }
      if (selectedData.customer?.id) {
        setSelectedCustomer(selectedData.customer);
      }

      let selectedFormData = {
        id: selectedData.id,
        quoteNumber: selectedData.quoteDetails.quoteNumber,
        companyName: selectedData.customerDetails.companyName,
        contactName: selectedData.customerContact
          ? selectedData.customerContact?.contactName
          : selectedData.customerDetails.contactName,
        contactNumber: selectedData.customerDetails.contactNumber,
        contactEmail: selectedData.customerDetails.contactEmail,
        customerPurchaseOrderNumber:
          selectedData.quoteDetails.customerPurchaseOrderNumber,
        customerLocation: selectedData.customerDetails.customerLocation
          .split(", ")
          .filter((item) => item !== "")
          .join(", "),
        locationLat: selectedData.customerDetails.locationLat,
        locationLng: selectedData.customerDetails.locationLng,
        urgencyId: selectedData.quoteDetails.urgencyId,
        assetTextId: selectedData.assetDetails.assetTextId,
        assetSerialNumber: selectedData.assetDetails.assetSerialNumber,
        makeId: selectedData.assetDetails.makeId
          ? selectedData.assetDetails.makeId
          : selectedData.assetDetails.otherAssetDetails
          ? 0
          : null,
        equipmentTypeId: selectedData.assetDetails.equipmentTypeId,
        assetId: selectedData.assetDetails.assetId,
        otherAssetDetails: selectedData.assetDetails.otherAssetDetails,
        assetPropValue: selectedData.assetDetails.assetPropValue,
        assetPropUnit: selectedData.assetDetails.assetPropUnit
          ? selectedData.assetDetails.assetPropUnit
          : "hrs",
        quoteDesc: selectedData.quoteDetails.quoteDesc,
        quoteImageDetails: selectedData.quoteDetails.quoteImageDetails,
        quoteStatusId: selectedData.quoteDetails.quoteStatusId,
        quoteStatusIdBeforeArchive:
          selectedData.quoteDetails.quoteStatusIdBeforeArchive,
        distanceTravelled:
          selectedData.quoteDetails.distanceTravelled != null
            ? selectedData.quoteDetails.distanceTravelled
            : "",
        hoursLabour:
          selectedData.quoteDetails.hoursLabour != null
            ? selectedData.quoteDetails.hoursLabour
            : "",
        hoseMaker: selectedData.hoseMaker ? selectedData.hoseMaker : [],
        inventoryComponents: selectedData.inventoryComponents,
        inventoryNonBOA: selectedData.inventoryNonBOA,
        notes: selectedData.quoteDetails.notes
          ? selectedData.quoteDetails.notes
          : "",
        makeName: selectedData.assetDetails.makeName
          ? selectedData.assetDetails.makeName
          : "",
        equipmentTypeName: selectedData.assetDetails.equipmentTypeName
          ? selectedData.assetDetails.equipmentTypeName
          : "",
        model: selectedData.assetDetails.model
          ? selectedData.assetDetails.model
          : "",
        isConflict: selectedData.quoteDetails.quoteNumber.includes("COPY")
          ? true
          : false,
        saveContactDetails: selectedData.saveContactDetails,
        customer: selectedData.customer?.companyName
          ? selectedData.customer
          : {},
        customerContact: selectedData.customerContact?.contactName
          ? selectedData.customerContact
          : {},
        makeName: selectedData.makeName ? selectedData.makeName : null,
        equipmentTypeName: selectedData.equipmentTypeName
          ? selectedData.equipmentTypeName
          : null,
        model: selectedData.model ? selectedData.model : null,
        selectCustomer: selectedData.customerId ? true : false,
        selectContact: selectedData.customerContactId ? true : false,
        technician: selectedData.technician,
        technicianEmail: selectedData.technicianEmail,
        expiryDateTime: selectedData.quoteDetails.expiryDate,
        quoteAdditionalImages: selectedData.quoteAdditionalImages,
        quoteAssetImages: selectedData.quoteAssetImages,
      };
      if (selectedData.technicianEmail) {
        //find technician if exist
        let technician = technicianList?.find(
          (item) => item.email === selectedData.technicianEmail
        );
        if (!technician) {
          setTechnicianDeleted(true);
        }
      }
      setSelectedDate(
        selectedData.quoteDetails.expiryDate
          ? new Date(selectedData.quoteDetails.expiryDate)
          : null
      );
      setQuoteImages(selectedData.quoteAssetImages);
      setQuoteAdditionalImages(selectedData.quoteAdditionalImages);
      let imagesFromData = [];
      let additionalImagesFromData = [];
      selectedData.quoteAssetImages?.map((item, i) => {
        imagesFromData.push(item.filePath);
      });
      selectedData.quoteAdditionalImages?.map((item, i) => {
        additionalImagesFromData.push(item.filePath);
      });
      selectedFormData.hoseMaker?.map((hose) => {
        hose.hoseIdTagging?.map((hoseTag) => {
          hose.hoseName = hoseTag.hoseName;
          hose.hoseOemNumber = hoseTag.hoseOemNumber;
          hose.isHoseCleaned = hoseTag.isHoseCleaned;
          hose.isVisuallyInspected = hoseTag.isVisuallyInspected;
          hose.isDesignStandard = hoseTag.isDesignStandard;
        });
      });
      setImages(imagesFromData);
      setAdditionalImages(additionalImagesFromData);
      setFormData(selectedFormData);
    }
  }, [selectedData]);

  useEffect(() => {
    if (selectedData && selectedData.customerDetails) {
      if (
        selectedData.customerDetails?.locationLat !== "" &&
        selectedData.customerDetails?.locationLng !== "" &&
        selectedData.customerDetails?.locationLat !== "string" &&
        selectedData.customerDetails?.locationLng !== "string"
      ) {
        const geolocation = {
          lat: Number(selectedData.customerDetails?.locationLat),
          lng: Number(selectedData.customerDetails?.locationLng),
        };
        if (
          geolocation.lat !== location.lat &&
          geolocation.lng != location.lng
        ) {
          setLocation(geolocation);
          setHasLocation(true);
        }
      }
    }
  }, [selectedData, hasLocation]);

  useEffect(() => {
    if (formData && formData?.makeId && formData?.equipmentTypeId) {
    } else {
      setAssetImage("");
    }
  }, [formData?.makeId, formData?.equipmentTypeId]);

  useEffect(() => {
    const defaultTechnicianFilter = localStorage.getItem(
      "defaultTechnicianFilter"
    );
    if (defaultTechnicianFilter !== technicianFilter) {
      if (technicianFilter === "" || technicianFilter === "All") {
        if (technicianFilter === "All") {
          onSetTechnicianFilter(technicianFilter);
          setTechnicianName("All");
        }
      } else {
        onSetTechnicianFilter(technicianFilter);
        let item = technicianList.find(
          (item) => item.value === technicianFilter
        );
        setTechnicianName(item ? item.text : "Unassigned");
      }
    } else {
      if (filterStatus >= 0) {
        const delayDebounceFn = setTimeout(
          () => {
            onSearch(
              quotingSearchKeyword,
              quotingSearchType,
              filterStatus,
              technicianFilter
            );
          },
          searchTerm === "" ? 100 : 2000
        );

        return () => clearTimeout(delayDebounceFn);
      }
    }
  }, [searchTerm, filterStatus, technicianFilter]);

  const onSetTechnicianFilter = async (technician) => {
    setIsLoading(true);
    try {
      const result = await apiSetTechnicianFilter(technician);
      if (result) {
        window.localStorage.setItem("defaultTechnicianFilter", technician);
        setIsLoading(false);
        onSearch(
          quotingSearchKeyword,
          quotingSearchType,
          filterStatus,
          technicianFilter
        );
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch (e) {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (assetType) {
      if (formData?.makeId !== 0) {
        getHosePositions();
      }
    }
  }, [assetType]);

  const handleFileChange = async (file) => {
    if (file) {
      if (file.size >= 25000000) {
        showErrorToast("File size cannot exceed 25MB.");
        setIsLoading(false);
        setFilesLoading(false);
        setImageLoading(false);
      }
      setIsLoading(true);
      setImageLoading(true);
      try {
        const result = await apiSaveQuoteImage(file, formData.id);
        if (result) {
          getImagesData();
          setIsLoading(false);
          setImageLoading(false);
          showSuccessToast(
            <div>
              <i className="check icon" /> Image Uploaded Successfully
            </div>
          );
        } else {
          setImageLoading(false);
          setIsLoading(false);
        }
      } catch {
        setImageLoading(false);
        setIsLoading(false);
      }
    } else {
    }
  };

  const onDeleteFile = async () => {
    setIsLoading(true);
    const assetData = await apiRemoveQuoteImage(selectedFile.id, formData.id);
    if (assetData) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Image Deleted Successfully
        </div>
      );
      setDeleteFileOpen(false);
      getImagesData();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const openAdditionalImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsAdditionalViewerOpen(true);
  }, []);

  const closeAdditionalImageViewer = () => {
    setCurrentImage(0);
    setIsAdditionalViewerOpen(false);
  };

  useEffect(() => {
    if (selectedId != "") {
      getQuotingData();
    }
  }, [selectedId]);

  return (
    <React.Fragment>
      <MetaData title="Quoting" />
      <Modal size="mini" open={openStockLocationError}>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <div style={{ textAlign: "left", marginBottom: 10 }}>
            <p style={{ color: "red" }}>Error</p>
          </div>
          <div
            style={{
              textAlign: "left",
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            <p>{stockLocationError.message}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => setOpenStockLocationError(false)}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={openSelectStockLocation}>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : null}
        <Modal.Header>Choose stock location</Modal.Header>
        <Modal.Content>
          <div
            style={{
              fontSize: 18,
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            <p>
              Please select the stock <br /> location for this quote
            </p>
          </div>
          <Form size="mini">
            <Form.Field>
              <Dropdown
                search
                placeholder="Select Stock Location"
                selection
                options={stocklocationList}
                value={formData?.boahubLocationId}
                onChange={(e, data) =>
                  handleChange("boahubLocationId", data.value)
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              onSubmitQuote(
                formData?.jobStatusId === 3 ? 3 : formData?.jobStatusId + 1,
                true
              );
            }}
          >
            SAVE
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenHoseMaker(false)}
        onOpen={() => setOpenHoseMaker(true)}
        open={openHoseMaker}
        className="hosemaker-modal"
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <HoseMakerNew
            onCancelHoseMaker={onCancelHoseMaker}
            data={selectedData}
            addBOM={addBOM}
            updateBOM={updateBOM}
            refreshJobData={setSelectedData}
            setHoseMakerIndex={setHoseMakerIndex}
            hoseMakerData={hoseMakerData}
            hoseMakerIndex={hoseMakerIndex}
            assetImage={assetImage}
            assetType={assetType}
            currentStatus={currentStatus}
            isAssemble={isAssemble}
            isQuoting={false}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenOtherComponents(false)}
        onOpen={() => setOpenOtherComponents(true)}
        open={openOtherComponents}
        className="other-components-modal"
      >
        <Modal.Content>
          <SearchComponent
            isHoseMaker={true}
            isJobbing={true}
            onProductClick={addOtherComponents}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        onClose={() => setOpenCustomItem(false)}
        onOpen={() => setOpenCustomItem(true)}
        open={openCustomItem}
        className="remove-bom"
        closeIcon
      >
        <Modal.Content>
          <Grid columns={1} style={{ margin: 0, marginTop: 10 }}>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column
                width={16}
                style={{
                  padding: 0,
                  margin: 0,
                  textAlign: "center",
                }}
              >
                <Header as="h4">Add Custom Item</Header>
                <Input
                  size="mini"
                  style={{ width: "70%" }}
                  value={customProductText}
                  onChange={(e, data) => setcCustomProductText(data.value)}
                />
                <Button
                  disabled={!customProductText}
                  positive
                  size="mini"
                  style={{ marginLeft: 5 }}
                  onClick={() => addCustomComponents()}
                >
                  <Icon name="plus" />
                  Add
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={openRemoveBOM}
        onClose={() => removeBOM(false)}
        className="remove-bom"
      >
        <Modal.Header>Remove BOM</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove BOM?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => removeBOM(false)}>Cancel</Button>
          <Button negative onClick={() => removeBOM(true)}>
            Remove
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openToInprogress}
        onClose={() => setOpenToInprogress(false)}
        className="remove-bom"
      >
        <Modal.Header>Move to In Progress</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>
              Are you sure you want to move #
              {selectedData?.quoteDetails?.quoteNumber} from "
              {formData?.jobStatusId == 3 ? "Completed" : "Archived"}" back to
              "In Progress"?
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenToInprogress(false)}>
            No
          </Button>
          <Button positive onClick={() => onSubmitQuote(2, false)}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openToDuplicate}
        onClose={() => setOpenToDuplicate(false)}
        className="remove-bom"
      >
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : null}
        <Modal.Header>Duplicate Quote</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>Are you sure you want to duplicate this quote?</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: "#FF1414", color: "#fff" }}
            onClick={() => setOpenToDuplicate(false)}
          >
            No
          </Button>
          <Button
            style={{ backgroundColor: "#66CB23", color: "#fff" }}
            onClick={() => onDuplicateQuote()}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openDeleteHoseMakerPopup}
        onClose={() => setOpenDeleteHoseMakerPopup(false)}
        className="remove-bom"
      >
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : null}
        <Modal.Header>Confirmation</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>Are you sure you want to delete</p>
            <p style={{ fontSize: 24, fontWeight: "bold" }}>
              HOSE ITEM #{hoseMakerIndex + 1}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: "#FF1414", color: "#fff" }}
            onClick={() => {
              setOpenDeleteHoseMakerPopup(false);
              setHoseMakerIndex(null);
            }}
          >
            No
          </Button>
          <Button
            style={{ backgroundColor: "#66CB23", color: "#fff" }}
            onClick={() => onHandleDeleteHoseMaker()}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openToInvoiced}
        onClose={() => setOpenToInvoiced(false)}
        className="remove-bom"
      >
        <Modal.Header>Move to Invoiced</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>
              Are you sure you want to move #
              {selectedData?.jobDetails?.jobNumber} to "Invoiced"?
            </p>
          </div>
          <div style={{ textAlign: "center", marginTop: 3 }}>
            <p>It cannot be moved back.</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenToInvoiced(false)}>
            No
          </Button>
          <Button positive onClick={() => onSubmitQuote(4, false)}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="mini"
        open={openIsDuplicateCustomer}
        onClose={() => setOpenIsDuplicateCustomer(false)}
        className="remove-bom"
      >
        <Modal.Header style={{ backgroundColor: "rgb(255, 143, 143)" }}>
          WARNNING
        </Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <p>{duplicateMessage}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenIsDuplicateCustomer(false)}>OK</Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={deleteFileOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete image?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteFileOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteFile()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      {isViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      {isViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      {isAdditionalViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isAdditionalViewerOpen && (
              <ImageViewer
                src={additionalImages}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeAdditionalImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      <Grid columns={3} divided className="quoting" style={{ marginTop: -30 }}>
        <Grid.Row style={{ paddingTop: 0, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            <h3 style={{ marginLeft: 20 }}>
              <img
                src={quotingIcon}
                style={{ width: 30, height: 30, marginTop: -5 }}
              />{" "}
              Quoting
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            computer={4}
            tablet={6}
            mobile={16}
            className="quote-list-section"
            style={{ paddingRight: 0 }}
          >
            <div
              className={
                quotingSearchTypeFullName === null
                  ? "quoting-search-box-nosearch"
                  : "quoting-search-box"
              }
            >
              <Search
                placeholder="Search..."
                results={quotingSearchOptions.map((item) => ({
                  title: item.value,
                  key: item.key,
                }))}
                open={openSearchList}
                onBlur={() =>
                  setTimeout(() => {
                    setOpenSearchList(false);
                  }, 100)
                }
                value={quotingSearchKeyword}
              />
              {quotingSearchTypeFullName && (
                <div className="quote-search-type">
                  {quotingSearchTypeFullName}
                </div>
              )}
            </div>
            <hr />
            <div
              className="quote-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <Select
                      style={{ fontSize: 13, width: "100%" }}
                      options={quoteStatusList}
                      value={filterStatus}
                      onChange={(e, data) => setFilterStatus(data.value)}
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 8, paddingBottom: 0 }}
                  >
                    <Dropdown
                      style={{ fontSize: 13, width: "100%" }}
                      basic
                      color="black"
                      text={
                        technicianFilter
                          ? technicianName
                          : "Filter by technician"
                      }
                      search
                      scrolling
                      icon="user"
                      floating
                      labeled
                      button
                      className="icon"
                      options={technicianFilterList}
                      onChange={(e, data) => {
                        setTechnicianFilter(data.value);
                        setTechnicianName(data.text);
                      }}
                      value={technicianFilter}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div style={{ marginTop: 5 }}>
                <Button
                  className="active-status"
                  icon="add"
                  size="mini"
                  style={{ width: "100%", fontSize: 14 }}
                  onClick={() => onNewQuote()}
                >
                  <Icon name="add" style={{ color: "#fff", marginLeft: -15 }} />
                  New Quote
                </Button>
              </div>
            </div>
            <div className="quote-list">
              <div style={{ position: "absolute", top: 300, left: 145 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading...
                  </Loader>
                ) : null}
              </div>
              {data.length === 0 && !searchLoading ? (
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: 25,
                      fontSize: 20,
                    }}
                  ></p>
                </div>
              ) : null}
              <div
                {...events}
                ref={ref}
                className="quote-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1 }}
              >
                <div style={{ marginTop: 0 }}>
                  {quoteListData.map((data, i) => {
                    return (
                      <Card
                        className={
                          selectedCard === data.quoteNumber
                            ? "quote-card active"
                            : "quote-card"
                        }
                      >
                        <Card.Content
                          onClick={() => {
                            if (selectedCard !== data.quoteNumber) {
                              setSelectedId(data.id);
                              setSelectedCard(data.quoteNumber);
                            } else {
                              setHasLocation(false);
                              setSelectedId("");
                              setSelectedCard("");
                              setSelectedData(null);
                              setFormData(null);
                              setAssetType("");
                              setAssetImage("");
                              setAssetSearch("");
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Grid columns={2} className="quote-management">
                            <Grid.Row>
                              <Grid.Column
                                width={14}
                                style={{ paddingTop: 10, paddingBottom: 6 }}
                              >
                                <b>
                                  <Card.Description>
                                    Quote #{data.quoteNumber}
                                  </Card.Description>
                                </b>
                                <Card.Description>
                                  Company: {data.companyName}
                                </Card.Description>
                                <Card.Description>
                                  Contact: {data.contactName}
                                </Card.Description>
                                <Card.Description>
                                  Quote Status: {data.quoteStatusDesc}
                                </Card.Description>
                              </Grid.Column>
                              <Grid.Column
                                width={2}
                                style={{
                                  padding: 0,
                                  backgroundColor:
                                    data.quoteStatusId === 1
                                      ? "#ff8f8f"
                                      : data.quoteStatusId === 2
                                      ? "#ffdda6"
                                      : data.quoteStatusId === 4
                                      ? "#71a8d2"
                                      : data.quoteStatusId === 0 ||
                                        data.quoteStatusId === 5
                                      ? "#ced4db"
                                      : "#c4f8c8",
                                }}
                              >
                                <div className="quote-state">
                                  <p className="quote-state-text">
                                    {data.quoteStatusId === 0
                                      ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                                        data.quoteStatusDesc
                                      : data.quoteStatusId === 1
                                      ? "\xa0\xa0\xa0\xa0" +
                                        data.quoteStatusDesc
                                      : data.quoteStatusId === 2
                                      ? "\xa0\xa0\xa0\xa0\xa0\xa0" +
                                        data.quoteStatusDesc
                                      : data.quoteStatusId === 3
                                      ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                                        data.quoteStatusDesc
                                      : data.quoteStatusId === 4
                                      ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                                        data.quoteStatusDesc
                                      : data.quoteStatusId === 5
                                      ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                                        data.quoteStatusDesc
                                      : data.quoteStatusDesc}
                                  </p>
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Content>
                      </Card>
                    );
                  })}
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={8}
            tablet={10}
            mobile={16}
            style={{ paddingRight: 0 }}
          >
            {selectedData ? (
              <div className="quote-form">
                <div className="quote-actions">
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      height: 45,
                      marginLeft: 0,
                      paddingLeft: 0,
                    }}
                  >
                    {formData?.quoteStatusId !== 5 && (
                      <Button
                        style={{
                          backgroundColor: "#979fa8",
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() =>
                          onSubmitQuote(formData?.quoteStatusId, false)
                        }
                      >
                        Save & Close
                      </Button>
                    )}
                    {formData?.quoteStatusId < 3 &&
                      formData?.quoteStatusId !== 5 &&
                      !formData?.isConflict && (
                        <Button
                          style={{
                            backgroundColor:
                              formData?.quoteStatusId === 2
                                ? "#66CC23"
                                : "#FFAD00",
                            color: "#fff",
                          }}
                          size="small"
                          onClick={() => {
                            if (formData?.quoteStatusId === 2) {
                              if (stocklocationList?.length > 1) {
                                onSubmitQuote(
                                  formData?.quoteStatusId === 3
                                    ? 3
                                    : formData?.quoteStatusId + 1,
                                  true
                                );
                                // setOpenSelectStockLocation(true);
                              } else {
                                handleChange(
                                  "boahubLocationId",
                                  stocklocationList[0]?.id
                                );
                                onSubmitQuote(
                                  formData?.quoteStatusId === 3
                                    ? 3
                                    : formData?.quoteStatusId + 1,
                                  true
                                );
                              }
                            } else {
                              onSubmitQuote(
                                formData?.quoteStatusId === 3
                                  ? 3
                                  : formData?.quoteStatusId + 1,
                                true
                              );
                            }
                          }}
                        >
                          {formData?.quoteStatusId === 2
                            ? "Save & Complete"
                            : "Save & Progress"}
                        </Button>
                      )}
                    {formData?.quoteStatusId === 5 ? (
                      <Button
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          backgroundColor: "#66cc23",
                          color: "#fff",
                          minWidth: 100,
                        }}
                        size="small"
                      >
                        <Icon name="undo" />
                        Restore
                      </Button>
                    ) : null}
                    {(formData?.quoteStatusId === 3 ||
                      formData?.quoteStatusId === 4 ||
                      (formData?.quoteStatusId === 5 &&
                        formData?.quoteStatusIdBeforeArchive === 3) ||
                      (formData?.quoteStatusId === 5 &&
                        formData?.quoteStatusIdBeforeArchive === 4)) && (
                      <Button
                        style={{
                          backgroundColor: "#66cc23",
                          color: "#fff",
                          minWidth: 100,
                        }}
                        size="small"
                      >
                        Download PDF
                      </Button>
                    )}
                    {formData?.quoteStatusId === 3 ? (
                      <Button
                        color="orange"
                        size="small"
                        onClick={() => setOpenToInprogress(true)}
                      >
                        Move to In Progress
                      </Button>
                    ) : null}
                    {formData?.quoteStatusId > 0 &&
                    formData?.quoteStatusId < 5 &&
                    formData?.id ? (
                      <Button
                        color="google plus"
                        size="small"
                        onClick={() => onArchiveQuote()}
                        style={{
                          position: "absolute",
                          right: formData?.quoteStatusId === 1 ? 115 : 12,
                          minWidth: 100,
                        }}
                      >
                        Archive
                      </Button>
                    ) : null}
                    {(formData?.quoteStatusId === 1 ||
                      formData?.quoteStatusId === 5) &&
                    formData?.id ? (
                      <Button
                        color="youtube"
                        size="small"
                        onClick={() => onDeleteQuote()}
                        style={{
                          position: "absolute",
                          right: 12,
                          paddingLeft: 15,
                          paddingRight: 15,
                          minWidth: 100,
                        }}
                      >
                        <Icon name="trash" />
                        Delete
                      </Button>
                    ) : null}
                  </Container>
                </div>
                <hr />
                <div
                  className="quote-form-content"
                  style={{ paddingRight: "0px !important" }}
                >
                  <Form size="tiny">
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column
                          width={16}
                          style={{ paddingBottom: 0 }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <div class="arrow-steps clearfix">
                            <div
                              className={
                                formData?.quoteStatusId == 0 ||
                                formData?.quoteStatusId == 1
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>New Request</a>
                              </span>
                            </div>
                            <div
                              className={
                                formData?.quoteStatusId == 2
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; In Progress
                                  &nbsp;
                                </a>
                              </span>
                            </div>
                            <div
                              className={
                                formData?.quoteStatusId == 3
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>&nbsp; &nbsp; &nbsp; Completed</a>
                              </span>
                            </div>
                            <div
                              className={
                                formData?.quoteStatusId == 4
                                  ? "step current"
                                  : "step"
                              }
                            >
                              <span>
                                <a>&nbsp; &nbsp; &nbsp; Accepted</a>
                              </span>
                            </div>
                            {formData?.quoteStatusId === 5 && (
                              <div
                                class="step archived"
                                style={{
                                  position: "absolute",
                                  right: 30,
                                  backgroundColor: "red !important",
                                }}
                              >
                                <span>
                                  <a>Archived</a>
                                </span>
                              </div>
                            )}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Header
                              as="h4"
                              style={{
                                textDecoration: "underline",
                                textUnderlineOffset: 5,
                                marginBottom: 0,
                              }}
                            >
                              {selectedData &&
                              selectedData.quoteDetails?.quoteNumber
                                ? `Quote #${formData?.quoteNumber}`
                                : `New Quote Request`}
                            </Header>
                            {formData?.quoteStatusId >= 2 ? (
                              <Button
                                style={{
                                  backgroundColor: "#FFAD00",
                                  color: "#fff",
                                  minWidth: 100,
                                }}
                                size="small"
                                onClick={() => {
                                  setOpenToDuplicate(true);
                                }}
                              >
                                Duplicate Quote
                              </Button>
                            ) : null}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Customer Details
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <Form.Field required>
                            <label>Customer</label>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column
                                width={12}
                                style={{ paddingRight: 0 }}
                              >
                                {formData?.selectCustomer ? (
                                  <CustomerSearch
                                    customerList={customerList}
                                    onSelectCustomer={setSelectedCustomer}
                                    companyName={formData?.companyName}
                                  />
                                ) : (
                                  <Input
                                    className={
                                      formData?.companyName === "" &&
                                      formData?.quoteStatusId > 0
                                        ? "error"
                                        : ""
                                    }
                                    value={formData?.companyName}
                                    onChange={(e, data) =>
                                      handleChange("companyName", data.value)
                                    }
                                  />
                                )}
                              </Grid.Column>
                              <Grid.Column
                                width={4}
                                style={{
                                  lineHeight: "1",
                                  textAlign: "center",
                                  paddingLeft: 5,
                                }}
                              >
                                <Button
                                  style={{
                                    fontSize: 11,
                                    lineHeight: "1.1",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={() =>
                                    handleChange(
                                      "selectCustomer",
                                      formData?.selectCustomer ? false : true
                                    )
                                  }
                                >
                                  {!formData?.selectCustomer
                                    ? "Search Customer"
                                    : "Input Manually"}
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          style={{ paddingLeft: 0 }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <Form.Field>
                            <label>Purchase Order #</label>
                            <Input
                              value={formData?.customerPurchaseOrderNumber}
                              onChange={(e, data) =>
                                handleChange(
                                  "customerPurchaseOrderNumber",
                                  data.value
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={8} style={{ paddingTop: 15 }}>
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Contact Name</label>
                            {!formData?.selectCustomer ? (
                              <Input
                                value={formData?.contactName}
                                onChange={(e, data) =>
                                  handleChange("contactName", data.value)
                                }
                              />
                            ) : (
                              <Grid columns={2} style={{ padding: 0 }}>
                                <Grid.Column
                                  width={12}
                                  style={{ paddingRight: 0 }}
                                >
                                  {formData?.selectContact ? (
                                    <ContactSearch
                                      customerList={customerList}
                                      customerId={selectedCustomer.id}
                                      onSelectContact={setSelectedContact}
                                      contactName={formData?.contactName}
                                    />
                                  ) : (
                                    <Input
                                      value={formData?.contactName}
                                      onChange={(e, data) =>
                                        handleChange("contactName", data.value)
                                      }
                                    />
                                  )}
                                </Grid.Column>
                                <Grid.Column
                                  width={4}
                                  style={{
                                    lineHeight: "1",
                                    textAlign: "center",
                                    paddingLeft: 5,
                                  }}
                                >
                                  <Button
                                    className={
                                      formData?.makeId === 0 && "active-status"
                                    }
                                    style={{
                                      fontSize: 11,
                                      lineHeight: "1.1",
                                      fontWeight: "bold",
                                      padding: "5px 5px",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    onClick={() =>
                                      handleChange(
                                        "selectContact",
                                        formData?.selectContact ? false : true
                                      )
                                    }
                                  >
                                    {!formData?.selectContact
                                      ? "Search Contact"
                                      : "Input Manually"}
                                  </Button>
                                </Grid.Column>
                              </Grid>
                            )}
                          </Form.Field>
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Email Address</label>
                            <Input
                              value={formData?.contactEmail}
                              onChange={(e, data) =>
                                handleChange("contactEmail", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Contact Number</label>
                            <Input
                              value={formData?.contactNumber}
                              onChange={(e, data) =>
                                handleChange("contactNumber", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Customer Discount</label>
                            <Input
                              label={{ basic: true, content: "%" }}
                              labelPosition="right"
                              value={formData?.discountPercent}
                              onChange={(e, data) =>
                                handleChange("discountPercent", data.value)
                              }
                            />
                          </Form.Field>
                          {(formData?.selectCustomer &&
                            formData?.selectContact) ||
                          (formData?.customer?.id &&
                            formData.customerContact?.id) ? null : (
                            <Form.Field
                              disabled={formData?.quoteStatusId > 2}
                              style={{
                                paddingTop: "0.5rem",
                                paddingBottom: "0.5rem",
                              }}
                            >
                              <Checkbox
                                className="custom-checkbox"
                                checked={formData?.saveContactDetails}
                                label="Save customer details"
                                style={{ color: "red" }}
                                onChange={(e, data) =>
                                  handleChange(
                                    "saveContactDetails",
                                    data.checked
                                  )
                                }
                              />
                            </Form.Field>
                          )}
                          <Form.Field>
                            <label
                              style={{
                                opacity: formData?.quoteStatusId > 2 ? 0.6 : 1,
                              }}
                            >
                              Location
                            </label>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column
                                width={12}
                                style={{ paddingRight: 0 }}
                              >
                                <Autocomplete
                                  disabled={formData?.quoteStatusId > 2}
                                  ref={locationRef}
                                  style={{ width: "100%" }}
                                  placeholder=""
                                  onPlaceSelected={(place, inputRef) => {
                                    onAddressSelection(place);
                                  }}
                                  types={["geocode"]}
                                  componentRestrictions={{
                                    country: countries,
                                  }}
                                  value={formData?.customerLocation}
                                  onChange={(e, data) =>
                                    handleChange("customerLocation", data)
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={4}
                                style={{
                                  lineHeight: "1",
                                  textAlign: "center",
                                  paddingLeft: 5,
                                }}
                              >
                                <Button
                                  className={
                                    formData?.makeId === 0 && "active-status"
                                  }
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "1.1",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={openGoogleMaps}
                                >
                                  Go to maps
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          style={{ padding: "15px 18px 0px 0px" }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <div style={{ height: "100%", width: "100%" }}>
                            <GoogleMapReact
                              bootstrapURLKeys={{ key: "" }}
                              defaultCenter={{
                                lat: -42.312144,
                                lng: 172.474153,
                              }}
                              defaultZoom={5}
                              zoom={hasLocation ? 11 : 5}
                              center={
                                hasLocation
                                  ? location
                                  : {
                                      lat: -42.312144,
                                      lng: 172.474153,
                                    }
                              }
                            ></GoogleMapReact>
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -12.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          disabled={formData?.quoteStatusId > 2}
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: "2rem",
                            paddingBottom: "1rem",
                          }}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Quote Details
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ paddingTop: 0 }}
                          disabled={formData?.quoteStatusId > 2}
                        >
                          <Form>
                            <Form.Field>
                              <label>Quote Description</label>
                              <TextArea
                                onChange={(e, data) =>
                                  handleChange("quoteDesc", data.value)
                                }
                                value={formData?.quoteDesc}
                              />
                            </Form.Field>
                          </Form>
                        </Grid.Column>
                        {quoteImages?.length > 0 ? (
                          <>
                            <Grid.Column
                              width={16}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                            >
                              <Form style={{ marginTop: "1rem" }}>
                                <Form.Field>
                                  <label>Quote Description Images</label>
                                </Form.Field>
                              </Form>
                              <Grid style={{ padding: 0, margin: 0 }}>
                                {quoteImages.map((data, index) => {
                                  return (
                                    <Grid.Column
                                      width={4}
                                      style={{ padding: 0, margin: 0 }}
                                    >
                                      <div
                                        className="ui image"
                                        style={{
                                          height: 130,
                                          width: 130,
                                          marginTop: 10,
                                        }}
                                      >
                                        <img
                                          onClick={() => openImageViewer(index)}
                                          src={data.filePath}
                                          alt="Image Preview"
                                          className="bomImage"
                                          style={{
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </Grid.Column>
                                  );
                                })}
                              </Grid>
                            </Grid.Column>
                          </>
                        ) : null}
                        <Grid.Column
                          width={8}
                          disabled={formData?.quoteStatusId > 2}
                          style={{ paddingTop: "1.5rem" }}
                        >
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Urgency</label>
                            <Select
                              options={urgenciesData}
                              value={formData?.urgencyId}
                              onChange={(e, data) =>
                                handleChange("urgencyId", data.value)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          disabled={formData?.quoteStatusId > 2}
                          style={{ paddingTop: "1.5rem" }}
                        >
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Technician</label>
                            <Select
                              text={
                                technicianDeleted &&
                                !formData?.technicianSelected
                                  ? formData?.technician
                                  : ""
                              }
                              search
                              options={technicianList}
                              value={formData?.technicianEmail}
                              selectOnBlur={false}
                              selectOnNavigation={false}
                              onOpen={() => {
                                setTechnicianDeleted(false);
                              }}
                              onClose={() => {
                                if (formData?.technicianEmail) {
                                  //find technician if exist
                                  let technician = technicianList.find(
                                    (item) =>
                                      item.email === formData?.technicianEmail
                                  );
                                  if (!technician) {
                                    setTechnicianDeleted(true);
                                  }
                                }
                              }}
                              onChange={(e, data) =>
                                handleChange("technicianEmail", data.value)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          disabled={formData?.quoteStatusId > 2}
                          style={{ paddingTop: "1.5rem" }}
                        >
                          <Form.Field disabled={formData?.quoteStatusId > 2}>
                            <label>Quote Expiry Date</label>
                            <DatePicker
                              dateFormat="dd/MM/yy hh:mm aa"
                              selected={selectedDate}
                              showTimeSelect
                              timeIntervals={15}
                              onChange={(date) =>
                                handleChange("expiryDateTime", date)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      {formData?.quoteStatusId === 2 ? (
                        <>
                          <Grid.Column
                            width={8}
                            style={{
                              marginTop: 30,
                              marginBottom: 15,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Hose Assemblies
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={3}
                            style={{
                              marginTop: 35,
                              marginBottom: 15,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          ></Grid.Column>
                          <Grid.Column
                            width={5}
                            style={{
                              marginTop: 25,
                              marginBottom: 15,
                              paddingRight: 15,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Button
                              className="active-status"
                              size="small"
                              style={{
                                width: "100%",
                                backgroundColor: "rgb(102, 204, 35)",
                                color: "#fff",
                                marginLeft: "auto",
                              }}
                              onClick={() => {
                                setIsAssemble(false);
                                onAutoSaveQuote(formData);
                                setHoseMakerIndex(null);
                                setHoseMakerData(null);
                                setOpenHoseMaker(true);
                              }}
                            >
                              Add Hose Assembly
                            </Button>
                          </Grid.Column>
                        </>
                      ) : null}
                      {formData?.quoteStatusId > 2 ? (
                        <>
                          <Grid.Column
                            width={8}
                            style={{
                              marginTop: 30,
                              marginBottom: 15,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Hose Assemblies
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              marginTop: 35,
                              marginBottom: 15,
                            }}
                          ></Grid.Column>
                        </>
                      ) : null}
                      <Grid.Column
                        width={16}
                        style={{
                          padding: "0px 17px",
                        }}
                      >
                        {formData?.hoseMaker?.map((data, index) => {
                          return (
                            <Card
                              style={{
                                border: "2px solid #bdbdbd",
                                width: "100%",
                              }}
                            >
                              <Card.Content style={{ maxHeight: 220 }}>
                                <Grid>
                                  <Grid.Column
                                    style={{
                                      paddingTop: 10,
                                      paddingLeft: 20,
                                      paddingBottom: 5,
                                      cursor: "pointer",
                                    }}
                                    width={7}
                                    disabled={formData?.quoteStatusId > 2}
                                    onClick={() => {
                                      setIsAssemble(false);
                                      onAutoSaveQuote(formData);
                                      setHoseMakerIndex(index);
                                      setHoseMakerData(data);
                                      setOpenHoseMaker(true);
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "bolder",
                                        fontSize: 18,
                                      }}
                                    >
                                      <span>HOSE ITEM #{index + 1}</span>
                                    </div>
                                    <Grid>
                                      <Grid.Column width={8}>
                                        <div
                                          style={{
                                            paddingTop: 7,
                                            fontSize: 14,
                                          }}
                                        >
                                          <span>Hose SKU:</span>
                                          <br />
                                          <span>Hose Name:</span>
                                          <br />
                                          <span>OEM Number:</span>
                                          <br />
                                          <span>OAL:</span>
                                          <br />
                                          <span>QTY:</span>
                                        </div>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={8}
                                        style={{
                                          paddingLeft: 0,
                                          textAlign: "right",
                                        }}
                                      >
                                        <div
                                          style={{
                                            paddingTop: 7,
                                            fontSize: 14,
                                            textAlign: "right",
                                            display: "inline-block", // Ensure the span is inline-block
                                            whiteSpace: "nowrap", // Prevent line breaks
                                            overflow: "hidden", // Hide overflow content
                                            textOverflow: "ellipsis", // Show ellipsis when content overflows
                                            maxWidth: "100%",
                                          }}
                                        >
                                          <span>
                                            {data.step1_Barcode
                                              ? data.step1_Barcode
                                              : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.hoseIdTagging &&
                                            data.hoseIdTagging[0]?.hoseName
                                              ? data.hoseIdTagging[0]?.hoseName
                                              : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.hoseIdTagging &&
                                            data.hoseIdTagging[0]?.hoseOemNumber
                                              ? data.hoseIdTagging[0]
                                                  ?.hoseOemNumber
                                              : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.oal ? data.oal + "mm" : ""}
                                          </span>
                                          <br />
                                          <span>
                                            {data.quantity ? data.quantity : ""}
                                          </span>
                                        </div>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={5}
                                    style={{
                                      textAlign: "center",
                                      paddingTop: 10,
                                      paddingBottom: 5,
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                      textOverflow: "nowrap",
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingTop: 6,
                                        fontSize: 12,
                                        textOverflow: "nowrap",
                                      }}
                                    >
                                      <span>
                                        Location:{" "}
                                        {getPositionName(data.positionId)}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Image
                                        style={{
                                          width: 120,
                                          height: 120,
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                        }}
                                        src={getPositionImage(data.positionId)}
                                        onClick={
                                          formData?.quoteStatusId === 2
                                            ? () => {
                                                setIsAssemble(false);
                                                onAutoSaveQuote(formData);
                                                setHoseMakerIndex(index);
                                                setHoseMakerData(data);
                                                setOpenHoseMaker(true);
                                              }
                                            : null
                                        }
                                      />
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={4}
                                    style={{
                                      padding: 0,
                                      paddingTop: 20,
                                      paddingLeft: 0,
                                      paddingRight: 0,
                                    }}
                                  >
                                    {formData.quoteStatusId < 3 ? (
                                      <Button
                                        style={{
                                          width: "90%",
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                          backgroundColor: "white",
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 25,
                                          paddingLeft: 0,
                                          margin: 0,
                                        }}
                                        onClick={() => {
                                          setOpenDeleteHoseMakerPopup(true);
                                          setHoseMakerIndex(index);
                                        }}
                                      >
                                        <Icon
                                          name="trash"
                                          style={{
                                            padding: 0,
                                            margin: 0,
                                            color: "#EF0E0E",
                                          }}
                                        />
                                      </Button>
                                    ) : null}
                                    <Button
                                      size="small"
                                      style={{
                                        width: "90%",
                                        border:
                                          data.bomAssembly?.lfActualCrimpSize >
                                            0 &&
                                          data.bomAssembly?.rfActualCrimpSize >
                                            0 &&
                                          data.bomAssembly
                                            ?.isMarkedPushedOnCorrectly === true
                                            ? "Solid 2px #66cc23"
                                            : null,
                                        backgroundColor:
                                          data.bomAssembly?.lfActualCrimpSize >
                                            0 &&
                                          data.bomAssembly?.rfActualCrimpSize >
                                            0 &&
                                          data.bomAssembly
                                            ?.isMarkedPushedOnCorrectly === true
                                            ? "#fff"
                                            : "#FFAD00",
                                        color:
                                          data.bomAssembly?.lfActualCrimpSize >
                                            0 &&
                                          data.bomAssembly?.rfActualCrimpSize >
                                            0 &&
                                          data.bomAssembly
                                            ?.isMarkedPushedOnCorrectly === true
                                            ? "#66cc23"
                                            : "#fff",
                                        fontSize: 12,
                                        // padding: "10px 5px",
                                        marginTop:
                                          formData.quoteStatusId < 3
                                            ? null
                                            : 40,
                                        opacity: "1 !important",
                                      }}
                                      onClick={() => {
                                        setIsAssemble(true);
                                        if (formData?.quoteStatusId === 2)
                                          onAutoSaveQuote(formData);
                                        setHoseMakerIndex(index);
                                        setHoseMakerData(data);
                                        setOpenHoseMaker(true);
                                      }}
                                    >
                                      {data.bomAssembly?.lfActualCrimpSize >
                                        0 &&
                                      data.bomAssembly?.rfActualCrimpSize > 0 &&
                                      data.bomAssembly
                                        ?.isMarkedPushedOnCorrectly === true
                                        ? "View Assembled BOM"
                                        : "Assemble BOM"}
                                    </Button>
                                    {data.bomAssembly?.lfActualCrimpSize > 0 &&
                                    data.bomAssembly?.rfActualCrimpSize > 0 &&
                                    data.bomAssembly
                                      ?.isMarkedPushedOnCorrectly === true &&
                                    data.bomAssembly?.createdDateTime !==
                                      null ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          paddingLeft: 2,
                                          marginTop: 35,
                                          marginRight: 10,
                                          fontSize: 12,
                                          color: "#AFABAB",
                                        }}
                                      >
                                        <label>
                                          Assembled at:{" "}
                                          {formatDateTime(
                                            data.bomAssembly.bomCreated
                                          )}
                                        </label>
                                      </div>
                                    ) : null}
                                  </Grid.Column>
                                </Grid>
                              </Card.Content>
                            </Card>
                          );
                        })}
                      </Grid.Column>
                      <Grid.Row
                        disabled={formData?.quoteStatusId > 2}
                        style={{ padding: 0 }}
                      >
                        {formData?.quoteStatusId === 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Inventory Components
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={3}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                            <Grid.Column
                              width={5}
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                paddingRight: 15,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => setOpenOtherComponents(true)}
                              >
                                Add Component
                              </Button>
                            </Grid.Column>
                          </>
                        ) : null}
                        {formData?.quoteStatusId > 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Inventory Components
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                          </>
                        ) : null}
                        <Grid.Column
                          width={16}
                          style={{
                            padding: "0px 17px",
                          }}
                        >
                          {formData?.inventoryComponents?.map((data, index) => {
                            return (
                              <div key={index}>
                                <Card
                                  style={{
                                    width: "100%",
                                  }}
                                  fluid
                                  color="green"
                                >
                                  <Card.Content>
                                    <Grid>
                                      <Grid.Column width={11}>
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: 14,
                                          }}
                                        >
                                          {`${data.barcode}`}
                                        </span>
                                        <Grid>
                                          <Grid.Column width={3}>
                                            <div
                                              style={{
                                                marginTop: 10,
                                                width: 75,
                                                height: 75,
                                              }}
                                            >
                                              <Image
                                                style={{
                                                  width: 75,
                                                  height: 75,
                                                }}
                                                src={
                                                  data.image128
                                                    ? `data:image/jpeg;base64,${data.image128}`
                                                    : productDefaultImage
                                                }
                                              />
                                            </div>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={13}
                                            style={{
                                              paddingTop: 35,
                                              paddingLeft: "1.5rem",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                              }}
                                            >
                                              {`${data.name}`}
                                            </span>
                                          </Grid.Column>
                                        </Grid>
                                      </Grid.Column>
                                      <Grid.Column width={4}>
                                        <Form
                                          style={{
                                            textAlign: "center",
                                            marginTop: 10,
                                          }}
                                        >
                                          <label>Quantity</label>
                                          <Form.Field>
                                            <NumericInputBox
                                              value={data.quantity}
                                              name="quantity"
                                              min={1}
                                              max={10000}
                                              maxLength={6}
                                              precision={0}
                                              step={1}
                                              onChangeHandler={(value) =>
                                                handleOtherComponentQuantityChange(
                                                  index,
                                                  value
                                                )
                                              }
                                              onKeyDownHandler={(e) => {
                                                if (e.key === "Enter")
                                                  e.preventDefault();
                                              }}
                                            />
                                          </Form.Field>
                                        </Form>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={1}
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          paddingTop: 45,
                                          textAlign: "center",
                                        }}
                                      >
                                        <Popup
                                          position="top center"
                                          trigger={
                                            <Icon
                                              name="trash"
                                              size="big"
                                              style={{
                                                cursor: "pointer",
                                                color: "red",
                                                marginLeft: -15,
                                                marginTop: 10,
                                              }}
                                              onClick={() =>
                                                handleOtherComponentQuantityChange(
                                                  index,
                                                  0
                                                )
                                              }
                                            />
                                          }
                                          content="Remove"
                                          style={{
                                            fontSize: 12,
                                            opacity: 0.7,
                                          }}
                                          inverted
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </Card.Content>
                                </Card>
                              </div>
                            );
                          })}
                        </Grid.Column>
                        {formData?.quoteStatusId === 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Non-BOA Items
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={3}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                            <Grid.Column
                              width={5}
                              style={{
                                marginTop: 25,
                                marginBottom: 15,
                                paddingRight: 15,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  setcCustomProductText("");
                                  setOpenCustomItem(true);
                                }}
                              >
                                Add Non-BOA Item
                              </Button>
                            </Grid.Column>
                          </>
                        ) : null}
                        {formData?.quoteStatusId > 2 ? (
                          <>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 30,
                                marginBottom: 15,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{
                                  color: "#66cc23",
                                }}
                              >
                                Non-BOA Items
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{
                                marginTop: 35,
                                marginBottom: 15,
                              }}
                            ></Grid.Column>
                          </>
                        ) : null}
                        <Grid.Column
                          width={16}
                          style={{
                            padding: "0px 17px",
                          }}
                        >
                          {formData?.inventoryNonBOA?.map((data, index) => {
                            return (
                              <div key={index}>
                                <Card
                                  style={{
                                    width: "100%",
                                  }}
                                  fluid
                                  color="green"
                                >
                                  <Card.Content>
                                    <Grid>
                                      <Grid.Column
                                        width={8}
                                        style={{
                                          paddingTop: 20,
                                          paddingBottom: 20,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: 16,
                                          }}
                                        >
                                          {`${data.description}`}
                                        </span>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={3}
                                        style={{
                                          paddingBottom: 20,
                                          paddingRight: 0,
                                        }}
                                      >
                                        <Form
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <label>Quantity</label>
                                          <Form.Field>
                                            <NumericInputBox
                                              value={data.quantity}
                                              name="quantity"
                                              min={0}
                                              max={10000}
                                              maxLength={6}
                                              precision={0}
                                              step={1}
                                              onChangeHandler={(value) =>
                                                handleCustomComponentQuantityChange(
                                                  index,
                                                  value
                                                )
                                              }
                                              onKeyDownHandler={(e) => {
                                                if (e.key === "Enter")
                                                  e.preventDefault();
                                              }}
                                            />
                                          </Form.Field>
                                        </Form>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={4}
                                        style={{ paddingBottom: 20 }}
                                      >
                                        <Form
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <label>Sell Price</label>
                                          <Form.Field>
                                            <NumericInputBox
                                              value={data.sellPrice}
                                              name="quantity"
                                              min={0}
                                              max={1000000}
                                              maxLength={6}
                                              precision={2}
                                              step={1}
                                              onChangeHandler={(value) =>
                                                handleCustomComponentPriceChange(
                                                  index,
                                                  value
                                                )
                                              }
                                              onKeyDownHandler={(e) => {
                                                if (e.key === "Enter")
                                                  e.preventDefault();
                                              }}
                                            />
                                          </Form.Field>
                                        </Form>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={1}
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          paddingTop: 45,
                                          textAlign: "center",
                                        }}
                                      >
                                        <Popup
                                          position="top center"
                                          trigger={
                                            <Icon
                                              name="trash"
                                              size="big"
                                              style={{
                                                cursor: "pointer",
                                                color: "red",
                                                marginLeft: -15,
                                              }}
                                              onClick={() =>
                                                handleCustomComponentQuantityChange(
                                                  index,
                                                  -1
                                                )
                                              }
                                            />
                                          }
                                          content="Remove"
                                          style={{
                                            fontSize: 12,
                                            opacity: 0.7,
                                          }}
                                          inverted
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </Card.Content>
                                </Card>
                              </div>
                            );
                          })}
                        </Grid.Column>
                      </Grid.Row>
                      {formData?.quoteStatusId > 1 ? (
                        <Grid.Row style={{ padding: 0 }}>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: 30,
                              marginBottom: 15,
                            }}
                            disabled={formData?.quoteStatusId > 2}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Quote Notes
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: -5,
                              marginBottom: 15,
                            }}
                            disabled={formData?.quoteStatusId > 2}
                          >
                            <TextArea
                              onChange={(e, data) =>
                                handleChange("notes", data.value)
                              }
                              value={formData?.notes}
                            />
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: 5,
                              marginBottom: 15,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{
                                color: "#66cc23",
                              }}
                            >
                              Additional Images
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: -5,
                              marginBottom: 15,
                            }}
                          >
                            <div
                              className="ui image"
                              style={{ marginBottom: 10 }}
                            >
                              {imageLoading ? (
                                <Button disabled size="mini">
                                  Uploading...
                                </Button>
                              ) : (
                                <QuoteImageUploader
                                  disabled={formData?.quoteStatusId > 2}
                                  setImage={handleFileChange}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: -5,
                              marginBottom: 15,
                            }}
                          >
                            <Grid style={{ padding: 0, margin: 0 }}>
                              {quoteAdditionalImages?.map((data, index) => {
                                return (
                                  <Grid.Column
                                    width={4}
                                    style={{ padding: 0, margin: 0 }}
                                  >
                                    <div
                                      className="ui image"
                                      style={{
                                        height: 130,
                                        width: 130,
                                        marginTop: 10,
                                      }}
                                    >
                                      <img
                                        onClick={() =>
                                          openAdditionalImageViewer(index)
                                        }
                                        src={data.filePath}
                                        alt="Image Preview"
                                        className="bomImage"
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                          cursor: "pointer",
                                        }}
                                      />
                                      {formData?.quoteStatusId < 3 && (
                                        <Button
                                          circular
                                          icon="settings"
                                          style={{
                                            position: "absolute",
                                            left: 115,
                                            top: -12,
                                            cursor: "pointer",
                                            padding: "4px 9px 7px 9px",
                                          }}
                                          onClick={() => {
                                            setSelectefFile(data);
                                            setDeleteFileOpen(true);
                                          }}
                                        >
                                          x
                                        </Button>
                                      )}
                                    </div>
                                  </Grid.Column>
                                );
                              })}
                            </Grid>
                          </Grid.Column>
                        </Grid.Row>
                      ) : null}
                    </Grid>
                  </Form>
                </div>
              </div>
            ) : dataLoading ? (
              <Skeleton count={30} />
            ) : null}
          </Grid.Column>
          <Grid.Column
            computer={4}
            tablet={16}
            mobile={16}
            style={{ paddingLeft: 0 }}
          >
            {selectedData ? (
              <div className="quote-info" style={{ paddingLeft: 10 }}>
                <Grid style={{ marginTop: 0 }}>
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column
                      width={16}
                      style={{
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      <div
                        className="ui image"
                        style={{
                          height: 150,
                          width: 150,
                          zIndex: 2,
                          marginTop: -20,
                        }}
                      >
                        {formData?.makeId !== 0 && (
                          <img
                            src={assetImage}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      computer={3}
                      tablet={2}
                      mobile={2}
                      style={{ marginTop: 10 }}
                    >
                      {formData?.quoteStatusId > 2 ? (
                        <div onClick={() => handleOpenAssetDetails()}>
                          <Image
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{
                              width: 18,
                              marginTop: -8.5,
                              transform: `rotate(${
                                clicked ? "90deg" : "0deg"
                              })`,
                              transition: "transform 0.5s ease",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ) : (
                        <Image
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -12.5 }}
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column
                      computer={13}
                      tablet={14}
                      mobile={14}
                      style={{ marginTop: 10 }}
                    >
                      <div disabled={formData?.quoteStatusId > 2}>
                        <Header
                          as="h5"
                          style={{ marginLeft: -30, marginTop: -1 }}
                        >
                          Asset Details
                        </Header>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={16} style={{ paddingTop: 0 }}>
                      <div
                        className="asset-details"
                        disabled={formData?.quoteStatusId > 2}
                        style={{
                          paddingTop:
                            clicked || formData?.quoteStatusId <= 2 ? 10 : 0,
                          height:
                            clicked || formData?.quoteStatusId <= 2 ? 500 : 0,
                          visibility:
                            clicked || formData?.quoteStatusId <= 2
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <Form size="tiny">
                          <Form.Field>
                            <label>Asset ID</label>
                            <Input
                              value={formData?.assetTextId}
                              onChange={(e, data) =>
                                handleChange("assetTextId", data.value)
                              }
                              placeholder="Asset ID / Plant Number / Rego"
                            />
                          </Form.Field>
                          <Form.Field>
                            <label>Asset Serial Number</label>
                            <Input
                              value={formData?.assetSerialNumber}
                              onChange={(e, data) =>
                                handleChange("assetSerialNumber", data.value)
                              }
                              placeholder="Serial Number"
                            />
                          </Form.Field>
                          <Form.Field required={formData?.quoteStatusId > 1}>
                            <Grid columns={2} style={{ padding: 0 }}>
                              <Grid.Column
                                width={12}
                                style={{ paddingRight: 0 }}
                              >
                                <AssetSearch
                                  enable={formData?.makeId !== 0}
                                  className="search-box"
                                  loading={assetLoading}
                                  placeholder={
                                    assetLoading ? "" : "Search Asset Details"
                                  }
                                  onResultSelect={handleResultSelect}
                                  onSearchChange={handleAssetSearch}
                                  noResultsMessage={
                                    assetLoading
                                      ? ""
                                      : "No direct matches found."
                                  }
                                  results={assetList}
                                  value={assetSearch}
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={4}
                                style={{
                                  lineHeight: "1",
                                  textAlign: "center",
                                  paddingLeft: 5,
                                }}
                              >
                                <Button
                                  className={
                                    formData?.makeId === 0 && "active-status"
                                  }
                                  style={{
                                    fontSize: 12,
                                    lineHeight: "1.1",
                                    fontWeight: "bold",
                                    padding: "5px 5px",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={() =>
                                    handleChange(
                                      "makeId",
                                      formData?.makeId === 0 ? null : 0
                                    )
                                  }
                                >
                                  Input Manually
                                </Button>
                              </Grid.Column>
                            </Grid>
                          </Form.Field>
                          {isLoading && formData?.quoteStatusId <= 2 ? (
                            <Dimmer active inverted>
                              <Loader size="large"></Loader>
                            </Dimmer>
                          ) : (
                            <>
                              <Form.Field
                                style={{
                                  opacity: formData?.makeId === 0 ? "1" : "0.7",
                                }}
                              >
                                <label>Manufacturer</label>
                                <Input
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    pointerEvents:
                                      formData?.makeId === 0 ? "" : "none",
                                    cursor:
                                      formData?.makeId === 0
                                        ? "none"
                                        : "not-allowed",
                                  }}
                                  onChange={(e, data) =>
                                    handleChange("makeName", data.value)
                                  }
                                  value={formData?.makeName}
                                />
                              </Form.Field>
                              <Form.Field
                                style={{
                                  opacity: formData?.makeId === 0 ? "1" : "0.7",
                                }}
                              >
                                <label>Equipment Type</label>
                                <Input
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    pointerEvents:
                                      formData?.makeId === 0 ? "" : "none",
                                    cursor:
                                      formData?.makeId === 0
                                        ? "none"
                                        : "not-allowed",
                                  }}
                                  onChange={(e, data) =>
                                    handleChange(
                                      "equipmentTypeName",
                                      data.value
                                    )
                                  }
                                  value={formData?.equipmentTypeName}
                                />
                              </Form.Field>
                              <Form.Field
                                style={{
                                  opacity: formData?.makeId === 0 ? "1" : "0.7",
                                }}
                              >
                                <label>Asset Model</label>
                                <Input
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    pointerEvents:
                                      formData?.makeId === 0 ? "" : "none",
                                    cursor:
                                      formData?.makeId === 0
                                        ? "none"
                                        : "not-allowed",
                                  }}
                                  onChange={(e, data) =>
                                    handleChange("model", data.value)
                                  }
                                  value={formData?.model}
                                />
                              </Form.Field>
                            </>
                          )}
                          <Form.Field>
                            <label>Asset Hours/Mileage</label>
                            <Input
                              type="number"
                              label={
                                <Dropdown
                                  defaultValue="hrs"
                                  value={formData?.assetPropUnit}
                                  options={options}
                                  onChange={(e, data) =>
                                    handleChange("assetPropUnit", data.value)
                                  }
                                />
                              }
                              labelPosition="right"
                              value={formData?.assetPropValue}
                              onChange={(e, data) =>
                                handleChange("assetPropValue", data.value)
                              }
                            />
                          </Form.Field>
                        </Form>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            ) : dataLoading ? (
              <Skeleton count={30} />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default Quoting;

import { useEffect, useState } from "react";

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export function padZero(number) {
  return number < 10 ? "0" + number : number;
}

export const sortKeysWithDatesFirst = (keys) => {
  const isDate = (str) => /^\d{2}\/\d{2}\/\d{2}$/.test(str);

  const toDateSortableFormat = (str) => {
    const [day, month, year] = str.split("/");
    return new Date(`20${year}-${month}-${day}`).getTime();
  };

  const isSpecialKey = (key) => ["Overdue", "Today", "Tomorrow"].includes(key);

  return keys.sort((a, b) => {
    if ((isSpecialKey(a) && isSpecialKey(b)) || (isDate(a) && isDate(b))) {
      if (isDate(a) && isDate(b)) {
        return toDateSortableFormat(a) - toDateSortableFormat(b);
      }
      return keys.indexOf(a) - keys.indexOf(b);
    }

    return isSpecialKey(a) ? -1 : 1;
  });
};

export const sortKeysWithDatesFirstDescending = (keys) => {
  const isDate = (str) => /^\d{2}\/\d{2}\/\d{2}$/.test(str);

  const toDateSortableFormat = (str) => {
    const [day, month, year] = str.split("/");
    return new Date(`20${year}-${month}-${day}`).getTime();
  };

  return keys.sort((a, b) => {
    if (isDate(a) && isDate(b)) {
      return toDateSortableFormat(b) - toDateSortableFormat(a);
    }
    return keys.indexOf(b) - keys.indexOf(a);
  });
};

export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxDimension = 256; // Max width or height of the compressed image

        let width = img.width;
        let height = img.height;

        if (height > maxDimension) {
          width *= maxDimension / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "#ffffff"; // White color
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const compressedFile = new File([blob], file.name, {
            type: "image/jpg", // Change the format if needed
          });
          resolve(compressedFile);
        }, "image/jpg"); // Change the format if needed
      };
    };

    reader.readAsDataURL(file);
  });
};

export function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes + ampm;
}

export function formatDateTime(data, withTime = true) {
  // Original datetime string
  let originalDatetimeStr = "2024-07-13T06:39:09.425";

  // Parse the original datetime string to a Date object
  let originalDatetimeObj = new Date(data);

  // Format the Date object to the desired format
  let day = String(originalDatetimeObj.getDate()).padStart(2, "0");
  let month = String(originalDatetimeObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  let year = originalDatetimeObj.getFullYear();
  let hours = String(originalDatetimeObj.getHours()).padStart(2, "0");
  let minutes = String(originalDatetimeObj.getMinutes()).padStart(2, "0");

  let formattedDatetimeStr = `${day}/${month}/${year} ${hours}:${minutes}`;

  if (!withTime) formattedDatetimeStr = `${day}/${month}/${year}`;

  return formattedDatetimeStr;
}

export function getFileExtension(filename) {
  const parts = filename.split(".");
  return parts.length > 1 ? parts.pop().toLowerCase() : "";
}

export function fileIsImage(file) {
  if (!file) return false;
  const imageTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/svg+xml",
  ];

  return imageTypes.includes(file.type);
}

export function fileNameIsImage(filename) {
  try {
    if (!filename) return false;
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
    const fileExtension = getFileExtension(filename);

    return imageExtensions.includes(fileExtension);
  } catch {
    return false;
  }
}

export function removeFileExtension(filename) {
  const parts = filename.split(".");
  if (parts.length > 1) {
    parts.pop();
    return parts.join(".");
  }
  return filename;
}

export const NumericInputChecker = (event) => {
  const { key, target } = event;
  const inputValue = target.value;

  // Check if there is already a decimal point
  const hasDecimal = inputValue.includes(".");
  const decimalIndex = inputValue.indexOf(".");
  const isAfterDecimal =
    decimalIndex !== -1 && target.selectionStart > decimalIndex;
  const digitsAfterDecimal = isAfterDecimal
    ? inputValue.slice(decimalIndex + 1).length
    : 0;

  // Allow control keys like Backspace, Delete, and Arrow keys
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

  if (
    // Allow numeric keys (0-9) if they don't exceed one digit after a decimal
    (!isNaN(key) && (!isAfterDecimal || digitsAfterDecimal < 1)) ||
    // Allow essential control keys
    allowedKeys.includes(key) ||
    // Allow one hyphen at the beginning
    (key === "-" && target.selectionStart === 0 && !inputValue.includes("-")) ||
    // Allow one decimal point
    (key === "." && !hasDecimal)
  ) {
    return; // Allow key input if conditions are met
  }

  // Prevent all other keys
  event.preventDefault();
};

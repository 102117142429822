import React, { useCallback, useEffect, useState } from "react";
import "./myAssets.css";
import {
  Card,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image as ImageContainer,
  Input,
  Loader,
  Button,
  Header,
  Modal,
  Select,
  TextArea,
} from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import { useRef } from "react";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import {
  apiGetMyAssetsList,
  apiAssetsSearch,
  apiGetHosePositions,
  apiGetUsersListWithCurrent,
  apiGetIndustryList,
  apiGetAssetLocationsList,
  apiAddAsset,
  apiGetAsset,
  apiUpdateAsset,
  apiDeleteAsset,
  apiSetCategory,
  apiAutoSaveAssetImage,
  apiAutoRemoveAssetImage,
  apiUploadFile,
  apiDeleteFile,
  apiGetBOApodsList,
  apiSubmitJob,
  apiGetServiceHistory,
  apiGetMyBOAPodListData,
  apiGetDTLocationsList,
  apiAutoSaveAssetChecklist,
  apiAutoRemoveAssetChecklist,
} from "./myAssets.api";
import { refreshToken } from "../Auth/auth.api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { categories, tempBookData, tempData } from "./data";
import AssetSearch from "./searchAsset";
import assetIcon from "../../assets/images/user-type-1.svg";
import Collapsible from "react-collapsible";
import AssetImageUploader from "./imageUploader";
import Autocomplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import emptyImage from "../../../src/assets/images/empty.png";
import {
  apiGeneratePDF,
  apiGetUrgencies,
  apiSaveJobAssetImage,
} from "../Jobbing/jobbing.api";
import JobImageUploader from "./jobImageUploader";
import AssetChecklistUploader from "./assetChecklistUploader";
import { fileNameIsImage, fileIsImage } from "../../utils/utils";
import ImageViewer from "react-simple-image-viewer";
import RS23 from "./RS23";

const MyAssets = (props) => {
  const [data, setData] = useState([]);
  const [userType, setUserType] = useState(0);
  const [assetType, setAssetType] = useState("");
  const [assetImage, setAssetImage] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [assetList, setAssetList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [assetLocationsList, setAssetLocationsList] = useState([]);
  const [categoryList, setCategoryList] = useState(categories);
  const [assetLoading, setAssetLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [assetSearch, setAssetSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteAssetOpen, setDeleteAssetOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [firstAssetId, setFirstAssetId] = useState("");
  const [assetSaved, setAssetSaved] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [fileSrc, setFileSrc] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [fileDataUrl, setFileDatafileDataUrl] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageRemoved, setImageRemoved] = useState(false);
  const [fileRemoved, setFileRemoved] = useState(false);
  const [assetFiles, setAssetFiles] = useState([]);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);
  const [selectedFile, setSelectefFile] = useState(null);
  const [filesLoading, setFilesLoading] = useState(false);
  const [bookAPod, setBookAPod] = useState(false);
  const [selectedPod, setSelectedPod] = useState({});
  const [myPodList, setMyPodList] = useState([]);
  const [podList, setPodList] = useState([]);
  const [podListData, setPodListData] = useState([]);
  const [podsLoading, setPodsLoading] = useState(false);
  const [myPodsLoading, setMyPodsLoading] = useState(false);
  const [bookData, setBookData] = useState(tempBookData);
  const [location, setLocation] = useState({
    lat: -42.312144,
    lng: 172.474153,
  });
  const [hasLocation, setHasLocation] = useState(false);
  const [urgenciesData, setUrgenciesData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [serviceHistories, setServiceHistories] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [connectedIds, setConnectedIds] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [assetHistory, setAssetHistory] = useState([]);
  const [assetLocations, setAssetLocations] = useState([]);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [countries, setCountries] = useState([]);
  const [images, setImages] = useState([]);
  const [openUpdateAssetHours, setOpenUpdateAssetHours] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [hasDTOpen, setHasDTOpen] = useState(false);

  // RS23
  const [viewRS23, setViewRS23] = useState(false);

  // Upload Job Images

  const getImages = (imageData = []) => {
    imageData.length > 0 &&
      imageData?.map((file) => {
        if (file) {
          if (file.size >= 25000000) {
            showErrorToast("File size cannot exceed 25MB.");
            setIsLoading(false);
            setFilesLoading(false);
            setImageLoading(false);
          }
        }
      });
    setImages(imageData);
  };

  useEffect(() => {
    // Get country list
    const countryList = JSON.parse(localStorage.getItem("countries"));
    setCountries(countryList);
  }, []);

  const ref = useRef();
  const imgRef = useRef(null);
  const fileInputRef = useRef(null);
  const locationRef = useRef(null);

  const mileage =
    localStorage.getItem("userCountryCode") === "US" ? "mi" : "km";

  // Unit options for hours/mileage
  const options = [
    { key: "hrs", text: "hrs", value: "hrs" },
    { key: mileage, text: mileage, value: mileage },
  ];

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let myAssets = newState.map((item, i) => {
      if (i == 0) {
        if (dataType == "makeId" && value == 0) {
          setAssetSearch("");
          setAssetType("");
          item.equipmentTypeId = null;
          item.assetDbId = null;
          setAssetImage("");
          setLocationList([]);
          localStorage.setItem("selectedAssetImage", "");
        }
        if (dataType == "makeId" && value != 0) {
          item.equipmentTypeId = null;
          item.assetDbId = null;
          item.otherAssetDetails = null;
          item.makeName = "";
          item.equipmentTypeName = "";
          item.model = "";
          setAssetType("");
          setAssetImage("");
          localStorage.setItem("selectedAssetImage", "");
          setLocationList([]);
        }
        if (dataType == "asset" && value) {
          item.assetDbId = value;
          item.otherAssetDetails = null;
          return item;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    console.log(myAssets[0]);
    setFormData(myAssets[0]);
  }

  function handleBookChange(dataType, value) {
    let newState = [];
    newState.push(bookData);
    let myBook = newState.map((item, i) => {
      if (i == 0) {
        item.assetId = formData.assetDbId;
        item.assetTextId = formData.assetId;
        item.companyAssetId = formData.id;
        if (dataType == "boapod" && value) {
          item.uniqueId = value.uniqueId;
          item.deviceId = value.id;
          setSelectedCompany(value.companyName);
          return item;
        }
        if (dataType == "customerLocation") {
          if (locationRef && locationRef.current)
            return {
              ...item,
              [dataType]: locationRef.current.refs.input.value,
            };
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setBookData(myBook[0]);
  }

  const onNewAsset = () => {
    setAssetHistory([]);
    setAssetLocations([]);
    setClicked(false);
    setServiceHistories([]);
    setAssetFiles([]);
    setImageRemoved(false);
    setImageData(null);
    setImageSrc(null);
    setFileRemoved(false);
    setFileData(null);
    setFileSrc(null);
    setAssetSaved(false);
    setIsEdit(false);
    setSelectedId(null);
    setSelectedCard(null);
    setSelectedData(null);
    setFormData(null);
    setAssetType("");
    setAssetImage("");
    setAssetSearch("");
    setImageLoading(false);
    localStorage.setItem("selectedAssetImage", "");
    const newData = {
      id: 0,
      assetId: "",
      assetSerialNumber: "",
      model: "",
      otherAssetDetails: "",
      assetPropValue: "",
      year: "",
      industry: "",
      assetLocationId: null,
      assetImageUrl: "",
      assetPrestartChecklistUrl: "",
      operator: "",
      nonBoaServiceParts: "",
      connectedAssets: [],
      parentId: null,
      assetDbId: "",
      makeName: "",
      equipmentTypeName: "",
      assetPropUnit: "hrs",
      connectedAssetsIds: [],
    };
    setSelectedData(newData);
    setFormData(newData);
  };

  const onSaveAsset = async () => {
    if (
      formData.assetId === "" ||
      formData.makeName === "" ||
      formData.equipmentTypeName === "" ||
      formData.model === "" ||
      formData.industry === ""
    ) {
      setAssetSaved(true);
      showErrorToast("Please complete all required fields");
      return false;
    }
    setIsLoading(true);
    if (formData.assetDbId === null) {
      formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
    }
    try {
      const result = await apiAddAsset(formData);
      if (result) {
        setSelectedId(result.id);
        setSelectedCard(result.id);
        setIsEdit(false);
        if (imageData !== null) await onAutoSaveAssetImage(result.id);
        if (fileData !== null) {
          const fileResult = await onAutoSaveAssetChecklist(result.id);
          if (fileResult) {
            getMyAssetsData(result.id);
          }
        }
        if (assetFiles.length > 0) {
          setFilesLoading(true);
          const results = await Promise.all(
            assetFiles.map((file) => {
              onUploadFile(file, result.id);
            })
          );
          console.log("Files uploaded successfully");
        }
        setAssetSaved(false);
        getMyAssetsListData();
        if (assetFiles.length > 0) {
          setTimeout(function () {
            showSuccessToast(
              <div>
                <i className="check icon" /> Asset Saved Successfully
              </div>
            );
            setIsLoading(false);
            setFilesLoading(false);
          }, 6000);
        } else {
          showSuccessToast(
            <div>
              <i className="check icon" /> Asset Saved Successfully
            </div>
          );
          setIsLoading(false);
          setFilesLoading(false);
        }
      } else {
        setAssetSaved(false);
        setIsLoading(false);
      }
    } catch (e) {
      setAssetSaved(false);
      setIsLoading(false);
    }
  };

  const onUploadFile = async (file, id) => {
    return await onAutoSaveUploadedFiles(file, id);
  };

  const onUploadJobImage = async (file, id, index) => {
    return await onAutoSaveUploadedJobImages(file, id, index);
  };

  const onSubmitCreateJob = async () => {
    if (bookData.uniqueId === "") {
      showErrorToast("Please complete all required fields");
      return false;
    }
    setIsLoading(true);
    try {
      const jobData = await apiSubmitJob(bookData, location);
      if (jobData) {
        if (images.length > 0) {
          const results = await Promise.all(
            images.map((file, index) => {
              onUploadJobImage(file, jobData, index);
            })
          );
        }
        if (assetFiles.length > 0) {
          setTimeout(function () {
            getMyAssetsListData();
            getMyAssetsData();
            getServiceHistoryData();
            setIsLoading(false);
            setBookData(tempBookData);
            showSuccessToast(
              <div>
                <i className="check icon" /> Your job has been successfully
                submitted to {selectedCompany}
              </div>
            );
            setBookAPod(false);
          }, 1000);
        } else {
          setTimeout(function () {
            getMyAssetsListData();
            getMyAssetsData();
            getServiceHistoryData();
            setIsLoading(false);
            setBookData(tempBookData);
            showSuccessToast(
              <div>
                <i className="check icon" /> Your job has been successfully
                submitted to {selectedCompany}
              </div>
            );
            setBookAPod(false);
          }, 500);
        }
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Please complete all required fields");
      setIsLoading(false);
    }
  };

  const onUpdateAsset = async () => {
    setHasDTOpen(false);
    if (
      formData.assetId === "" ||
      formData.makeName === "" ||
      formData.equipmentTypeName === "" ||
      formData.model === "" ||
      formData.industry === ""
    ) {
      setAssetSaved(true);
      showErrorToast("Please complete all required fields");
      return false;
    }
    setIsLoading(true);
    if (formData.assetDbId === null) {
      formData.otherAssetDetails = `${formData.makeName} | ${formData.equipmentTypeName} | ${formData.model}`;
    }
    try {
      const result = await apiUpdateAsset(formData);
      if (result) {
        if (imageData !== null) await onAutoSaveAssetImage(formData.id);
        if (fileData !== null) {
          const fileResult = await onAutoSaveAssetChecklist(formData.id);
          if (fileResult) {
            getMyAssetsData(formData.id);
          }
        }
        if (imageRemoved && formData.assetImageUrl !== "") {
          await onAutoRemoveAssetImage(formData.id);
        }
        if (fileRemoved && formData.assetPrestartChecklistUrl !== "") {
          await onAutoRemoveAssetChecklist(formData.id);
        }
        setAssetSaved(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> Asset Updated Successfully
          </div>
        );
        getMyAssetsListData();
        getMyAssetsData();
        setIsEdit(false);
        setIsLoading(false);
      } else {
        setAssetSaved(false);
        setIsLoading(false);
      }
    } catch (e) {
      setAssetSaved(false);
      setIsLoading(false);
    }
  };

  const onDeleteAsset = async () => {
    setIsLoading(true);
    const assetData = await apiDeleteAsset(formData);
    if (assetData) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Asset Deleted Successfully
        </div>
      );
      setDeleteAssetOpen(false);
      getMyAssetsListData();
      setIsEdit(false);
      setIsLoading(false);
      setSelectedId(null);
      setSelectedCard(null);
      setSelectedData(null);
      setFormData(null);
      setAssetType("");
      setAssetImage("");
      setAssetSearch("");
      localStorage.setItem("selectedAssetImage", "");
      if (assetHistory.length > 0) handleBack();
    } else {
      setIsLoading(false);
    }
  };

  const onDeleteFile = async () => {
    setIsLoading(true);
    if (!formData?.id) {
      const objectsToRemove = [selectedFile];
      let filteredArray = assetFiles.filter((objA) => {
        return !objectsToRemove.some((objToRemove) => objA === objToRemove);
      });
      setAssetFiles(filteredArray);
      setDeleteFileOpen(false);
      setIsLoading(false);
      showSuccessToast(
        <div>
          <i className="check icon" /> File Deleted Successfully
        </div>
      );
    } else {
      const assetData = await apiDeleteFile(selectedFile.id, formData.id);
      if (assetData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> File Deleted Successfully
          </div>
        );
        setDeleteFileOpen(false);
        getMyAssetsData();
      } else {
        setIsLoading(false);
      }
    }
  };

  const onSetCategory = async () => {
    setIsLoading(true);
    try {
      const result = await apiSetCategory(selectedCategory);
      if (result) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Asset Category Updated Successfully
          </div>
        );
        setTimeout(function () {
          setCategoryOpen(false);
          getMyAssetsListData();
          getMyAssetsData();
          setIsEdit(false);
          setIsLoading(false);
        }, 300);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch (e) {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSearch = async (query) => {
    setSearchLoading(true);
    const result = await apiGetMyAssetsList(query);
    if (result) {
      setData(result);
      setSearchLoading(false);
    }
  };

  const getMyAssetsListData = async () => {
    // if (setFirstAssetId === "") setIsLoading(true);
    const result = await apiGetMyAssetsList(searchTerm);
    if (result) {
      setData(result);
    }
  };

  const getAssetList = async (search) => {
    setAssetLoading(true);
    setAssetList([]);
    if (search === "") {
      setAssetList([]);
      setAssetLoading(false);
      return false;
    }
    const assetsData = await apiAssetsSearch(search);
    if (assetsData) {
      setAssetList(assetsData);
      setAssetLoading(false);
    } else {
      setAssetLoading(false);
    }
  };

  const getMyAssetsData = async (id = null) => {
    setBookAPod(false);
    setClicked(false);
    setImageRemoved(false);
    setFileRemoved(false);
    setIsLoading(true);
    const result = await apiGetAsset(id ? id : selectedId);
    if (result) {
      setFormData(result);
      setSelectedData(result);
      setIsLoading(false);
      setOpenUpdateAssetHours(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
      setOpenUpdateAssetHours(false);
    }
  };

  const getMyAssetsDataWithoutLoading = async (id) => {
    const result = await apiGetAsset(id);
    if (result) {
      setFormData(result);
      setSelectedData(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getServiceHistoryData = async () => {
    setHistoryLoading(true);
    const result = await apiGetServiceHistory(selectedId);
    if (result) {
      setServiceHistories(result);
      setHistoryLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setHistoryLoading(false);
    }
  };

  const getDTLocationsData = async () => {
    if (!formData) return false;
    setIsLoading(true);
    const result = await apiGetDTLocationsList(selectedId, formData.assetDbId);
    if (result) {
      setAssetLocations(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getUsersList = async () => {
    const result = await apiGetUsersListWithCurrent();
    if (result) {
      const list = result.filter(
        (item, index) => result.indexOf(item) === index
      );
      setOperatorList(list);
      setAssetLoading(false);
    } else {
      setAssetLoading(false);
    }
  };

  const getIndustryList = async () => {
    const result = await apiGetIndustryList();
    if (result) {
      setIndustryList(result);
      setAssetLoading(false);
    } else {
      setAssetLoading(false);
    }
  };

  const getAssetLocationsData = async () => {
    const result = await apiGetAssetLocationsList();
    if (result) {
      setAssetLocationsList(result);
      setAssetLoading(false);
    } else {
      setAssetLoading(false);
    }
  };

  const getMyPodsData = async () => {
    setMyPodsLoading(true);
    const result = await apiGetMyBOAPodListData();
    if (result) {
      setMyPodList(result);
      setMyPodsLoading(false);
    } else {
      setMyPodsLoading(false);
    }
  };

  const getBOApodsData = async () => {
    setPodsLoading(true);
    try {
      const result = await apiGetBOApodsList(currentLocation);
      if (result) {
        setPodList(result);
        setPodsLoading(false);
      }
    } catch {
      setPodsLoading(false);
    }
  };

  const getDropdownData = async () => {
    const urgencies = await apiGetUrgencies();
    if (urgencies) {
      setUrgenciesData(urgencies);
    }
  };

  const getHosePositions = async () => {
    setImageLoading(true);
    setAssetImage("");
    localStorage.setItem("selectedAssetImage", "");
    const hosePositions = await apiGetHosePositions(assetType);
    if (hosePositions) {
      setAssetImage(hosePositions.url);
      setLocationList(hosePositions.positions);
      setImageLoading(false);
      localStorage.setItem("selectedAssetImage", hosePositions.url);
    } else {
      setAssetImage("");
      setLocationList([]);
      setImageLoading(false);
      localStorage.setItem("selectedAssetImage", "");
    }
  };

  const handleResultSelect = (e, { result }) => {
    setAssetLoading(true);
    const fullDescription = result.displayName.split(" | ");
    formData.makeName = fullDescription[0];
    formData.equipmentTypeName = fullDescription[1];
    formData.model = fullDescription[2];
    setAssetType(fullDescription[1]);
    handleChange("asset", result.id);
    setTimeout(function () {
      setAssetLoading(false);
    }, 500);
  };

  const handleAssetSearch = async (e, { value }) => {
    setAssetSearch(value);
  };

  const onAddressSelection = (place) => {
    handleBookChange("customerLocation", locationRef.current.refs.input.value);
    if (place && place.geometry) {
      const geolocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLocation(geolocation);
      setHasLocation(true);
    }
  };

  const onAutoSaveAssetImage = async (id) => {
    setIsLoading(true);
    try {
      const image = await apiAutoSaveAssetImage(imageData, id);
      if (image) {
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onAutoRemoveAssetImage = async (id) => {
    try {
      const logo = await apiAutoRemoveAssetImage(id);
      if (logo) {
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onAutoSaveAssetChecklist = async (id) => {
    setIsLoading(true);
    try {
      const file = await apiAutoSaveAssetChecklist(fileData, id);
      if (file) {
        setIsLoading(false);
        return true;
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
        return false;
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
      return false;
    }
  };

  const onAutoRemoveAssetChecklist = async (id) => {
    try {
      const file = await apiAutoRemoveAssetChecklist(id);
      if (file) {
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onAutoSaveUploadedFiles = async (file, id) => {
    if (file) {
      const result = await apiUploadFile(file, id);
      if (result) {
        setIsLoading(false);
        setTimeout(function () {
          getMyAssetsDataWithoutLoading(id);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onAutoSaveUploadedJobImages = async (file, id, index) => {
    if (file) {
      const result = await apiSaveJobAssetImage(file, id, index);
      if (result) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getImageWidth = () => {
    if (imgRef.current) {
      const width = imgRef.current.width;
      setImageWidth(width);
    }
  };

  const getImage = async (image) => {
    if (image instanceof File) {
      const selectedFile = image;
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes

      try {
        const compressedImage = await compressImage(selectedFile);
        setImageData(compressedImage);
      } catch (error) {
        showErrorToast("Error compressing image: " + error);
      }
    }
  };

  const getFile = async (file) => {
    if (file instanceof File) {
      setFileRemoved(false);
      const selectedFile = file;

      if (selectedFile && fileIsImage(selectedFile)) {
        try {
          const compressedImage = await compressImage(selectedFile);
          setFileData(selectedFile);
        } catch (error) {
          showErrorToast("Error compressing image: " + error);
        }
      } else {
        if (file.size >= 50000000) {
          showErrorToast("File size cannot exceed 50MB.");
          return false;
        }
        setFileData(file);
      }
    }
  };

  const compressImage = (file) => {
    if (file.size >= 5000000) {
      return file;
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 256; // Max width or height of the compressed image

          let width = img.width;
          let height = img.height;

          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.fillStyle = "#ffffff"; // White color
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: "image/jpg", // Change the format if needed
            });
            resolve(compressedFile);
          }, "image/jpg"); // Change the format if needed
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const getImageSrc = async (image) => {
    if (image) {
      setImageSrc(image);
    }
  };

  const getFileSrc = async (file) => {
    if (file) {
      setFileSrc(file);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    setFilesLoading(true);
    const file = e.target.files[0];
    if (file) {
      if (file.size >= 25000000) {
        showErrorToast("File size cannot exceed 25MB.");
        setIsLoading(false);
        setFilesLoading(false);
      }
      if (!formData?.id) {
        let list = [];
        list = assetFiles;
        list.push(file);
        setAssetFiles(list);
        setFilesLoading(false);
        setTimeout(function () {
          handleChange("assetsUploaded", assetFiles.length);
        }, 300);
        return false;
      }
      setIsLoading(true);
      try {
        const result = await apiUploadFile(file, formData.id);
        if (result) {
          getMyAssetsData();
          setIsLoading(false);
          setFilesLoading(false);
          showSuccessToast(
            <div>
              <i className="check icon" /> File Uploaded Successfully
            </div>
          );
        } else {
          showErrorToast("Something went wrong");
          setIsLoading(false);
          setFilesLoading(false);
        }
      } catch {
        showErrorToast("Something went wrong");
        setIsLoading(false);
        setFilesLoading(false);
      }
    } else {
      setFilesLoading(false);
    }
  };

  const handleDownload = (file) => {
    if (!file.filePath) {
      showErrorToast("Save asset first before downloading this file");
      return false;
    }
    try {
      fetch(file.filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = file.fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch {
      showErrorToast("Something went wrong");
    }
  };

  const handleDownloadFile = (filePath) => {
    if (!filePath) {
      showErrorToast("Save asset first before downloading this file");
      return false;
    }
    try {
      const parts = filePath.split("/");
      const fileName = parts[parts.length - 1];
      fetch(filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch {
      showErrorToast("Something went wrong");
    }
  };

  const handleDownloadLocal = (file) => {
    // Create a URL for the file
    const url = URL.createObjectURL(file);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name; // Set the file name for download

    // Append the anchor to the body
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up resources
    URL.revokeObjectURL(url);
  };

  const handleOpenHistory = () => {
    setClicked(!clicked);
  };

  const handleBack = () => {
    const history = assetHistory;
    const previousAssetId = history[history.length - 1];
    setSelectedId(previousAssetId);
    setSelectedCard(previousAssetId);
    history.pop();
    setAssetHistory(history);
  };

  const onGeneratePDF = async (id, jobNumber, type) => {
    setIsLoading(true);
    try {
      const pdfData = await apiGeneratePDF(id, jobNumber, type);
      if (pdfData) {
        setIsLoading(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> PDF Generated Successfully
          </div>
        );
      } else {
        setIsLoading(false);
        showErrorToast("Generate PDF Failed");
      }
    } catch {
      setIsLoading(false);
      showErrorToast("Generate PDF Failed");
    }
  };

  const openImageViewer = useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAssetList(assetSearch);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [assetSearch]);

  useEffect(() => {
    if (selectedId != null) {
      setViewRS23(false);
      getMyAssetsData();
      getServiceHistoryData();
      getDTLocationsData();

      window.localStorage.removeItem("selectedAssetId");

      const thirdLevelArrays = Object.values(data);
      const combinedArray = [].concat(...thirdLevelArrays);
      const filteredArray = combinedArray.filter(
        (item) => item.Id !== selectedId
      );
      filteredArray.map((item) => {
        if (item.OtherAssetDetails !== "") {
          item.text = item.AssetId;
          const fullDescription = item.OtherAssetDetails.split(" | ");
          item.EquipmentType = fullDescription[1] ? fullDescription[1] : "";
          item.Model = fullDescription[2] ? fullDescription[2] : "";
        } else {
          item.name = `${item.AssetId} | ${item.EquipmentType} | ${item.Model}`;
          item.text = item.AssetId;
        }
        item.value = item.Id;
      });
      setAssetsList(filteredArray);
    }
  }, [selectedId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchTerm);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (assetType) {
      getHosePositions();
    }
  }, [assetType]);

  useEffect(() => {
    if (formData?.connectedAssetsIds?.length > 0) {
      const filteredArray = assetsList.filter((item) =>
        formData?.connectedAssetsIds.includes(item.Id)
      );
      setConnectedIds(filteredArray);
    } else {
      setConnectedIds([]);
    }
  }, [formData?.connectedAssetsIds]);

  useEffect(() => {
    if (formData?.id) {
      getDTLocationsData();
    }
  }, [formData?.id]);

  useEffect(() => {
    if (firstAssetId !== "") {
      const selectedAssetId = localStorage.getItem("selectedAssetId");
      if (selectedAssetId !== null) {
        setSelectedId(Number(selectedAssetId));
        setSelectedCard(Number(selectedAssetId));
      } else {
        setSelectedId(firstAssetId);
        setSelectedCard(firstAssetId);
      }
    }
  }, [firstAssetId]);

  useEffect(() => {
    if (assetImage !== "") {
      if (formData?.id === 0 && assetSearch === "") {
        localStorage.setItem("selectedAssetImage", "");
        setAssetImage("");
        setLocationList([]);
      }
    }
  }, [assetImage]);

  useEffect(() => {
    if (
      currentLocation &&
      currentLocation.lat !== null &&
      currentLocation.lng !== null
    )
      getBOApodsData();
  }, [currentLocation]);

  useEffect(() => {
    if (formData?.id === 0 && assetFiles.length > 0) {
      handleChange("assetsUploaded", assetFiles.length);
    }
  }, [assetFiles]);

  useEffect(() => {
    if (localStorage.getItem("boahubFindPod") !== "true") {
      setPodListData(myPodList);
    } else if (myPodList.length > 0 && podList.length > 0) {
      const filteredArray = podList.filter((itemA) => {
        return !myPodList.some((itemB) => itemA.id === itemB.deviceId);
      });
      const mergedArray = myPodList.concat(filteredArray);

      setPodListData(mergedArray);
    } else {
      if (podList.length > 0) setPodListData(podList);
    }
  }, [podList, myPodList]);

  useEffect(() => {
    if (selectedData && selectedId) {
      setImageRemoved(false);
      setImageData(null);
      setImageSrc(null);
      setFileRemoved(false);
      setFileData(null);
      setFileSrc(null);
      setBookData(tempBookData);
      setAssetSearch("");
      if (assetType !== selectedData.assetDetails.equipmentTypeName) {
        if (
          selectedData.assetDetails.equipmentTypeName === null ||
          selectedData.assetDetails.equipmentTypeName === ""
        ) {
          localStorage.setItem("selectedAssetImage", "");
          setAssetImage("");
          setAssetType("");
        } else {
          setAssetType(
            selectedData.assetDetails.equipmentTypeName
              ? selectedData.assetDetails.equipmentTypeName
              : ""
          );
        }
      }
      if (
        selectedData.assetDetails?.otherAssetDetails !== null &&
        selectedData.assetDetails?.otherAssetDetails !== ""
      ) {
        const fullDescription =
          selectedData.assetDetails.otherAssetDetails.split(" | ");
        selectedData.makeName = fullDescription[0] ? fullDescription[0] : "";
        selectedData.equipmentTypeName = fullDescription[1]
          ? fullDescription[1]
          : "";
        selectedData.model = fullDescription[2] ? fullDescription[2] : "";
        selectedData.assetDetails.makeName = fullDescription[0]
          ? fullDescription[0]
          : "";
        selectedData.assetDetails.equipmentTypeName = fullDescription[1]
          ? fullDescription[1]
          : "";
        selectedData.assetDetails.model = fullDescription[2]
          ? fullDescription[2]
          : "";
        selectedData.makeId = 0;
      } else {
        selectedData.makeName = selectedData.assetDetails?.makeName;
        selectedData.equipmentTypeName =
          selectedData.assetDetails?.equipmentTypeName;
        selectedData.model = selectedData.assetDetails?.model;
        selectedData.equipmentTypeId =
          selectedData.assetDetails?.equipmentTypeId;
        selectedData.makeId = selectedData.assetDetails?.makeId;
      }
      const selectedFormData = {
        id: selectedData.asset.id,
        assetId: selectedData.asset.assetId,
        assetSerialNumber: selectedData.asset.assetSerialNumber,
        year: selectedData.asset.year,
        industry: selectedData.asset.industry,
        assetLocationId: selectedData.asset.assetLocationId,
        assetImageUrl: selectedData.asset.assetImageUrl,
        assetPrestartChecklistUrl: selectedData.asset.assetPrestartChecklistUrl,
        operator: selectedData.asset.operator,
        nonBoaServiceParts: selectedData.asset.nonBoaServiceParts,
        parentId: selectedData.asset.parentId,
        assetDbId: selectedData.asset.assetDbId,
        otherAssetDetails: selectedData.assetDetails.otherAssetDetails,
        makeName: selectedData.assetDetails.makeName
          ? selectedData.assetDetails.makeName
          : "",
        equipmentTypeName: selectedData.assetDetails.equipmentTypeName
          ? selectedData.assetDetails.equipmentTypeName
          : "",
        model: selectedData.assetDetails.model
          ? selectedData.assetDetails.model
          : "",
        assetPropUnit: selectedData.assetDetails.assetPropUnit
          ? selectedData.assetDetails.assetPropUnit.toLowerCase()
          : "",
        assetPropValue: selectedData.assetDetails.assetPropValue
          ? selectedData.assetDetails.assetPropValue
          : "",
        equipmentTypeId: selectedData.equipmentTypeId,
        makeId: selectedData.makeId,
      };
      selectedFormData.connectedAssetsIds = [];
      selectedData.asset.connectedAssets.map((item) => {
        selectedFormData.connectedAssetsIds.push(item.assetId);
      });
      setFormData(selectedFormData);
      setAssetFiles(selectedData.assetFiles ? selectedData.assetFiles : []);
    }
  }, [selectedData]);

  const assetCategories = Object.entries(data).map(
    ([category, assets], index) => (
      <Collapsible
        trigger={`${category} (${assets.length})`}
        open={index === 0 ? true : false}
      >
        {assets.map((assetData, i) => {
          if (index === 0 && i === 0 && firstAssetId === "") {
            setFirstAssetId(assetData.Id);
          }
          if (assetData.OtherAssetDetails !== "") {
            const fullDescription = assetData.OtherAssetDetails.split(" | ");
            assetData.Manufacturer = fullDescription[0]
              ? fullDescription[0]
              : "";
            assetData.Model = fullDescription[2] ? fullDescription[2] : "";
          }
          return (
            <Card
              key={assetData.Id}
              className={
                selectedCard === assetData.Id ? "job-card active" : "job-card"
              }
            >
              <Card.Content
                style={{ minHeight: 90, cursor: "pointer" }}
                onClick={() => {
                  if (selectedCard !== assetData.Id) {
                    setAssetHistory([]);
                    setAssetLocations([]);
                    setSelectedId(assetData.Id);
                    setSelectedCard(assetData.Id);
                  } else {
                    setSelectedId(null);
                    setSelectedCard(null);
                    setSelectedData(null);
                    setFormData(null);
                    setAssetType("");
                    setAssetImage("");
                    setAssetSearch("");
                    localStorage.setItem("selectedAssetImage", "");
                  }
                }}
              >
                <Grid>
                  <Grid.Row style={{ paddingRight: 0 }}>
                    <Grid.Column
                      width={16}
                      style={{ paddingTop: 6, paddingBottom: 6, fontSize: 14 }}
                    >
                      <Grid style={{ padding: 20 }}>
                        <Grid.Column width={16} style={{ padding: 0 }}>
                          <b>
                            <Card.Description>
                              {assetData.AssetId}{" "}
                            </Card.Description>
                          </b>
                        </Grid.Column>
                        <Grid.Column width={2} style={{ padding: 0 }}>
                          <Card.Description style={{ marginTop: 10 }}>
                            <Icon
                              name="chart bar"
                              style={{ color: "#66CB23" }}
                            />
                          </Card.Description>
                        </Grid.Column>
                        <Grid.Column width={14} style={{ padding: 0 }}>
                          <Card.Description style={{ marginTop: 10 }}>
                            {assetData.Manufacturer}
                          </Card.Description>
                        </Grid.Column>
                        <Grid.Column width={2} style={{ padding: 0 }}>
                          <Card.Description>
                            <Icon name="cube" style={{ color: "#66CB23" }} />
                          </Card.Description>
                        </Grid.Column>
                        <Grid.Column width={14} style={{ padding: 0 }}>
                          <Card.Description>{assetData.Model}</Card.Description>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
      </Collapsible>
    )
  );

  const serviceHistoriesGroup = Object.entries(serviceHistories).map(
    ([category, list], index) => (
      <div>
        <span style={{ fontSize: 11, marginLeft: 2 }}>{category}</span>
        {list.map((data, i) => {
          return (
            <Card className="job-card">
              <Card.Content style={{ cursor: "pointer" }}>
                <Grid columns={2} className="job-management">
                  <Grid.Row>
                    <Grid.Column
                      width={14}
                      style={{
                        paddingTop: 10,
                        paddingBottom: 0,
                      }}
                    >
                      <b>
                        <Card.Description
                          style={{
                            fontSize: 13,
                            marginBottom: 2,
                          }}
                        >
                          <Icon name="user" style={{ color: "#66CB23" }} />{" "}
                          {data.CompanyName}
                        </Card.Description>
                      </b>
                      <Card.Description
                        style={{
                          color: "#66CB23",
                          fontSize: 11,
                        }}
                      >
                        Job Number
                      </Card.Description>
                      <Card.Description
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {data.JobNumber}
                      </Card.Description>
                      <Card.Description
                        style={{
                          color: "#66CB23",
                          fontSize: 11,
                        }}
                      >
                        {data?.JobStatusId > 2 ? "Number of Hoses" : "Urgency"}
                      </Card.Description>
                      <Card.Description
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {data?.JobStatusId > 2
                          ? data.HoseQuantity
                          : data.JobUrgency}
                      </Card.Description>
                      {data?.JobStatusId > 2 &&
                        data?.JobStatusId !== 5 &&
                        data?.JobStatusId !== 99 && (
                          <span
                            style={{
                              position: "absolute",
                              right: -6,
                              top: 0,
                              cursor: "pointer",
                              padding: "4px 9px 7px 9px",
                              fontSize: 12,
                            }}
                            onClick={() =>
                              onGeneratePDF(data.JobId, data.JobNumber, 2)
                            }
                          >
                            <Icon name="download" />
                          </span>
                        )}
                    </Grid.Column>
                    <Grid.Column
                      width={2}
                      style={{
                        padding: 0,
                        backgroundColor:
                          data.JobStatusId === 1
                            ? "#ff8f8f"
                            : data.JobStatusId === 2
                            ? "#ffdda6"
                            : data.JobStatusId === 4
                            ? "#71a8d2"
                            : data.JobStatusId === 0 || data.JobStatusId === 5
                            ? "#ced4db"
                            : data.JobStatusId === 99
                            ? "#ced4db"
                            : "#c4f8c8",
                      }}
                    >
                      <div className="job-state">
                        <p className="job-state-text">
                          {data.JobStatusId === 4
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.JobStatusDesc
                            : data.JobStatusId === 3
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.JobStatusDesc
                            : data.JobStatusId === 0
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.JobStatusDesc
                            : data.JobStatusId === 5
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.JobStatusDesc
                            : data.JobStatusId === 99
                            ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                              data.JobStatusDesc
                            : data.JobStatusDesc}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
      </div>
    )
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const userTypeData = localStorage.getItem("userType");
      setUserType(Number(userTypeData));
      const defaultAssetCategory = localStorage.getItem("defaultAssetCategory");
      setSelectedCategory(defaultAssetCategory);

      getMyPodsData();
      getUsersList();
      getIndustryList();
      getAssetLocationsData();
      getDropdownData();

      // Get Location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            // Set coordinates to zero if navigator fails to get user's coordinates
            setCurrentLocation({
              lat: 0,
              lng: 0,
            });
            // Identify what caused the navigator error
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.log("Location access denied by user");
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable");
                break;
              case error.TIMEOUT:
                console.log("Location request timed out");
                break;
              default:
                console.log("An unknown error occurred");
                break;
            }
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const handleImageLoad = (e) => {
    const img = e.target;
    const aspectRatio = img.naturalWidth / img.naturalHeight;
    setAspectRatio(aspectRatio);
  };

  const handleCircleClick = (e) => {
    // Update circle position based on click coordinates relative to the image
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - boundingRect.left;
    const y = e.clientY - boundingRect.top;
    setCirclePosition({ x, y });
  };

  return (
    <React.Fragment>
      <MetaData title="My Assets" />
      <Modal
        size="small"
        open={hasDTOpen}
        style={{ width: 400, marginTop: -150, marginLeft: 80 }}
      >
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{
            opacity: isLoading ? 0.2 : 1,
            textAlign: "center",
            padding: 40,
          }}
        >
          <p>
            Hydraulic hose line mapping will transfer to the new asset model
            selected.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onUpdateAsset()}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={deleteAssetOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete asset?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteAssetOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteAsset(true)}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={deleteFileOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete file?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteFileOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteFile()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={categoryOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Header>SET ASSET CATEGORY</Modal.Header>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <Grid>
            <Grid.Column width={16}>
              <div>
                {categoryList?.map((data, index) => {
                  return (
                    <Button
                      style={{
                        width: "100%",
                        textAlign: "left",
                        marginBottom: 5,
                        backgroundColor:
                          selectedCategory === data.category
                            ? "rgb(102, 204, 35)"
                            : "",
                        color: selectedCategory === data.category ? "#fff" : "",
                      }}
                      onClick={() => {
                        window.localStorage.setItem(
                          "defaultAssetCategory",
                          data.category
                        );
                        setSelectedCategory(data.category);
                      }}
                    >
                      {data.category}
                    </Button>
                  );
                })}
              </div>
            </Grid.Column>
            <Grid.Column width={8} style={{ paddingRight: 5 }}>
              <Button
                style={{ width: "100%" }}
                onClick={() => setCategoryOpen(false)}
              >
                Cancel
              </Button>
            </Grid.Column>
            <Grid.Column width={8} style={{ paddingLeft: 5 }}>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "rgb(102, 204, 35)",
                  color: "#fff",
                }}
                onClick={() => onSetCategory()}
              >
                Save
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        onClose={() => {
          setOpenUpdateAssetHours(false);
        }}
        onOpen={() => setOpenUpdateAssetHours(true)}
        open={openUpdateAssetHours}
        className="remove-bom"
        closeIcon
        closeOnDimmerClick={false}
      >
        <div style={{ position: "absolute", bottom: 100, left: 175 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content style={{ opacity: isLoading ? 0.2 : 1 }}>
          <Grid columns={1} style={{ margin: 0, marginTop: 10 }}>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column
                width={16}
                style={{
                  padding: 0,
                  margin: 0,
                  marginBottom: "1rem",
                  textAlign: "center",
                }}
              >
                <Header as="h4">Update Asset Hours</Header>
              </Grid.Column>
              <Grid.Column width={16} style={{}}>
                <Form>
                  <Form.Field>
                    <label>Asset Hours/Mileage</label>
                    <Input
                      type="number"
                      label={
                        <Dropdown
                          defaultValue="hrs"
                          value={formData?.assetPropUnit}
                          options={options}
                          onChange={(e, data) =>
                            handleChange("assetPropUnit", data.value)
                          }
                        />
                      }
                      labelPosition="right"
                      onChange={(e, data) =>
                        handleChange("assetPropValue", data.value)
                      }
                    />
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column width={4} style={{ paddingTop: 30 }}></Grid.Column>
              <Grid.Column width={12} style={{ paddingTop: 30 }}>
                <div className="job-actions" style={{ textAlign: "right" }}>
                  {assetHistory.length > 0 && (
                    <Button
                      style={{
                        minWidth: 100,
                      }}
                      size="small"
                      onClick={() => handleBack()}
                    >
                      <Icon name="arrow left" /> Back
                    </Button>
                  )}
                  <Button
                    style={{
                      minWidth: 100,
                    }}
                    color="youtube"
                    size="small"
                    onClick={() => {
                      if (formData?.id > 0) {
                        getMyAssetsData();
                        setIsEdit(false);
                        setOpenUpdateAssetHours(false);
                      } else {
                        setIsEdit(false);
                        setSelectedId(null);
                        setSelectedCard(null);
                        setSelectedData(null);
                        setFormData(null);
                        setAssetType("");
                        setAssetImage("");
                        setAssetSearch("");
                        localStorage.setItem("selectedAssetImage", "");
                        setOpenUpdateAssetHours(false);
                      }
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    style={{
                      minWidth: 100,
                      backgroundColor: "#66cc23",
                      color: "#fff",
                      marginRight: 0,
                    }}
                    size="small"
                    onClick={() => {
                      if (formData.id > 0) {
                        onUpdateAsset();
                      } else {
                        onSaveAsset();
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      {isViewerOpen && (
        <Modal className="hosemaker-modal" open={true}>
          <Modal.Content style={{ height: 700 }}>
            {isViewerOpen && (
              <ImageViewer
                src={
                  formData.assetPrestartChecklistUrl
                    ? [formData.assetPrestartChecklistUrl]
                    : [URL.createObjectURL(fileData)]
                }
                currentIndex={0}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
      <Grid
        columns={3}
        divided
        className="my-assets"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 0, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            {formData?.id > 0 ? (
              <h3 style={{ marginLeft: 20 }}>
                <img
                  src={assetIcon}
                  style={{ width: 45, height: 45, marginTop: -5 }}
                />{" "}
                My Assets - {formData?.assetId}
              </h3>
            ) : (
              <h3 style={{ marginLeft: 20 }}>
                <img
                  src={assetIcon}
                  style={{ width: 45, height: 45, marginTop: -5 }}
                />{" "}
                My Assets
              </h3>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            computer={4}
            tablet={6}
            mobile={16}
            className="job-list-section"
            style={{ paddingRight: 0, paddingTop: 0 }}
          >
            <div className="job-header">
              <Input
                icon="search"
                placeholder="Search for your asset"
                style={{ width: "100%" }}
                size="small"
                onChange={(e, data) => setSearchTerm(data.value)}
                loading={searchLoading}
                value={searchTerm}
              />
            </div>
            <hr />
            <div
              className="job-status"
              style={{ paddingRight: "0px !important" }}
            >
              <div style={{ marginTop: 5, paddingRight: 12 }}>
                <Button
                  size="mini"
                  style={{ width: "100%", fontSize: 14 }}
                  onClick={() => setCategoryOpen(true)}
                >
                  Set Category
                </Button>
              </div>
              <div style={{ marginTop: 5, paddingRight: 12 }}>
                {userType === 4 || userType === 7 || userType === 10 ? null : (
                  <Button
                    className="active-status"
                    icon="add"
                    size="mini"
                    style={{ width: "100%", fontSize: 14 }}
                    onClick={() => onNewAsset()}
                  >
                    Add Asset
                  </Button>
                )}
              </div>
            </div>
            <div className="job-list">
              <div ref={ref} className="job-list-scroll">
                {assetCategories}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={viewRS23 ? 12 : 8}
            tablet={10}
            mobile={16}
            style={{ paddingRight: 0 }}
          >
            {selectedData ? (
              <div className="job-form">
                {viewRS23 ? (
                  <div className="job-actions">
                    <Button
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        minWidth: 100,
                      }}
                      size="small"
                      onClick={() => setViewRS23(false)}
                    >
                      <Icon name="arrow left" /> Back to Asset Details
                    </Button>
                  </div>
                ) : bookAPod ? (
                  <div className="job-actions">
                    {assetHistory.length > 0 && (
                      <Button
                        style={{
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() => handleBack()}
                      >
                        <Icon name="arrow left" /> Back
                      </Button>
                    )}
                    <Button
                      style={{
                        minWidth: 100,
                        visibility:
                          userType === 4 || userType === 7 || userType === 10
                            ? "hidden"
                            : "visible",
                      }}
                      color="youtube"
                      size="small"
                      onClick={() => {
                        setBookAPod(false);
                        setBookData(tempBookData);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        minWidth: 100,
                        backgroundColor: "#66cc23",
                        color: "#fff",
                      }}
                      size="small"
                      onClick={() => {
                        onSubmitCreateJob();
                      }}
                    >
                      Submit Job
                    </Button>
                  </div>
                ) : formData?.id > 0 && !isEdit ? (
                  <div className="job-actions">
                    {assetHistory.length > 0 && (
                      <Button
                        style={{
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() => handleBack()}
                      >
                        <Icon name="arrow left" /> Back
                      </Button>
                    )}
                    <Button
                      style={{
                        minWidth: 100,
                        backgroundColor: "#66cc23",
                        color: "#fff",
                      }}
                      size="small"
                      onClick={() => setBookAPod(true)}
                    >
                      Book a Job
                    </Button>
                    <Button
                      style={{
                        minWidth: 100,
                        visibility:
                          userType === 4 || userType === 7 || userType === 10
                            ? "hidden"
                            : "visible",
                      }}
                      color="youtube"
                      size="small"
                      onClick={() => setDeleteAssetOpen(true)}
                    >
                      Delete
                    </Button>
                    <Button
                      style={{
                        color: "#fff",
                        backgroundColor: "#979fa8",
                        minWidth: 100,
                        visibility:
                          userType === 4 || userType === 7 || userType === 10
                            ? "hidden"
                            : "visible",
                      }}
                      size="small"
                      onClick={() => setIsEdit(true)}
                    >
                      Edit
                    </Button>
                    {localStorage.getItem("boahubIsRS23") === "true" ? (
                      <Button
                        style={{
                          position: "absolute",
                          right: 13,
                          minWidth: 100,
                          backgroundColor: "#66cc23",
                          color: "#fff",
                        }}
                        size="small"
                        onClick={() => setViewRS23(true)}
                      >
                        View RS23
                      </Button>
                    ) : null}
                  </div>
                ) : (
                  <div className="job-actions">
                    {assetHistory.length > 0 && (
                      <Button
                        style={{
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() => handleBack()}
                      >
                        <Icon name="arrow left" /> Back
                      </Button>
                    )}
                    <Button
                      style={{
                        minWidth: 100,
                      }}
                      color="youtube"
                      size="small"
                      onClick={() => {
                        if (formData?.id > 0) {
                          getMyAssetsData();
                          setIsEdit(false);
                        } else {
                          setIsEdit(false);
                          setSelectedId(null);
                          setSelectedCard(null);
                          setSelectedData(null);
                          setFormData(null);
                          setAssetType("");
                          setAssetImage("");
                          setAssetSearch("");
                          localStorage.setItem("selectedAssetImage", "");
                        }
                      }}
                    >
                      Discard
                    </Button>
                    <Button
                      style={{
                        minWidth: 100,
                        backgroundColor: "#66cc23",
                        color: "#fff",
                      }}
                      size="small"
                      onClick={() => {
                        if (formData.id > 0) {
                          if (assetLocations.length > 0) {
                            if (
                              selectedData.asset.assetDbId !==
                              formData.assetDbId
                            ) {
                              setHasDTOpen(true);
                            }
                          } else {
                            onUpdateAsset();
                          }
                        } else {
                          onSaveAsset();
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                )}
                <hr />
                <div style={{ paddingRight: "0px !important" }}>
                  {!bookAPod && !viewRS23 ? (
                    <Form size="tiny">
                      <Grid style={{ paddingTop: 15, marginRight: 0 }}>
                        <Grid.Column
                          computer={1}
                          tablet={2}
                          mobile={2}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                          disabled={isLoading || (formData?.id > 0 && !isEdit)}
                        >
                          <ImageContainer
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{ marginTop: -8.5 }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={15}
                          tablet={14}
                          mobile={14}
                          style={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            paddingRight: 0,
                          }}
                          disabled={isLoading || (formData?.id > 0 && !isEdit)}
                        >
                          <Header as="h5" style={{ marginLeft: -15 }}>
                            Asset Details
                          </Header>
                        </Grid.Column>
                      </Grid>
                      <div className="job-list-scroll" style={{ height: 650 }}>
                        <Grid style={{ paddingTop: 0 }}>
                          <Grid.Column width={8} style={{ paddingTop: 15 }}>
                            {formData.id > 0 && (
                              <div
                                style={{
                                  minHeight: 314,
                                }}
                              >
                                {assetImage ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                      marginBottom: 10,
                                    }}
                                  >
                                    <img
                                      src={assetImage}
                                      alt="Example Image"
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        border: "3px solid #66cc23",
                                      }}
                                    />
                                    {assetImage &&
                                      assetLocations?.map((data) => {
                                        const adjustedX = data.locationX * 100;
                                        const adjustedY = data.locationY * 100;
                                        return (
                                          <svg
                                            width="100%"
                                            height="100%"
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              overflow: "visible",
                                            }}
                                          >
                                            <circle
                                              cx={`${adjustedX}%`}
                                              cy={`${adjustedY}%`}
                                              r={8}
                                              fill="#66cc23"
                                            />
                                            {data.isPublic && (
                                              <circle
                                                cx={`${adjustedX}%`}
                                                cy={`${adjustedY}%`}
                                                r={4.5}
                                                fill="#326310"
                                              />
                                            )}
                                            <rect
                                              id="rectangle"
                                              x={`${adjustedX - 7}%`}
                                              y={`${adjustedY - 10}%`}
                                              width={35}
                                              height={15}
                                              fill="rgba(228, 228, 228, 0.5)" // Adjust the rectangle color as needed
                                              stroke="black" // Adjust the border color as needed
                                              strokeWidth={1}
                                              rx={2}
                                              ry={2}
                                            />
                                            <text
                                              id="text"
                                              x={`${adjustedX - 1.5}%`}
                                              y={`${adjustedY - 7}%`}
                                              dominantBaseline="middle"
                                              textAnchor="middle"
                                              fill="#000" // Adjust the text color as needed
                                            >
                                              {data.configuredHoses}/
                                              {data.totalHoses}
                                            </text>
                                          </svg>
                                        );
                                      })}
                                  </div>
                                ) : imageLoading ? (
                                  <div
                                    style={{
                                      height: 314,
                                      width: 314,
                                      textAlign: "center",
                                      paddingTop: 120,
                                      color: "#979797",
                                    }}
                                  >
                                    <img
                                      src={emptyImage}
                                      alt="Example Image"
                                      style={{ width: 314, height: 314 }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      height: 300,
                                      width: 300,
                                      textAlign: "center",
                                      paddingTop: 120,
                                      color: "#979797",
                                    }}
                                  >
                                    <span>No Schematic Available</span>
                                  </div>
                                )}
                                <Button
                                  disabled={imageLoading}
                                  className="active-status"
                                  icon="add"
                                  size="mini"
                                  style={{
                                    visibility:
                                      assetImage !== "" ? "visible" : "hidden",
                                    width: "100%",
                                    fontSize: 13.5,
                                    lineHeight: 1.5,
                                  }}
                                  onClick={() => {
                                    localStorage.setItem(
                                      "selectedAssetId",
                                      selectedId
                                    );
                                    setTimeout(function () {
                                      props.history.push("/hose-map");
                                    }, 500);
                                  }}
                                >
                                  Asset Hydraulic Hose Map <br />
                                  {"(Try Test Version)"}
                                </Button>
                              </div>
                            )}
                            <div>
                              <Form size="tiny" onSubmit={handleSubmit}>
                                <Form.Field
                                  required
                                  style={{
                                    marginTop: "1em",
                                  }}
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                >
                                  <label>Asset ID</label>
                                  <Input
                                    className={
                                      formData?.assetId === "" && assetSaved
                                        ? "error"
                                        : ""
                                    }
                                    value={formData?.assetId}
                                    onChange={(e, data) =>
                                      handleChange("assetId", data.value)
                                    }
                                    placeholder="Asset ID / Plant Number / Rego"
                                  />
                                </Form.Field>
                                <Form.Field
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                >
                                  <label>Asset Serial Number</label>
                                  <Input
                                    value={formData?.assetSerialNumber}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "assetSerialNumber",
                                        data.value
                                      )
                                    }
                                    placeholder="Serial Number"
                                  />
                                </Form.Field>
                                <Form.Field
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                >
                                  <label>Search Asset</label>
                                  <Grid columns={2} style={{ padding: 0 }}>
                                    <Grid.Column
                                      width={12}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <AssetSearch
                                        enable={formData?.makeId !== 0}
                                        className="search-box"
                                        loading={assetLoading}
                                        placeholder={
                                          assetLoading
                                            ? ""
                                            : "Search Asset Details"
                                        }
                                        onResultSelect={handleResultSelect}
                                        onSearchChange={handleAssetSearch}
                                        noResultsMessage={
                                          assetLoading
                                            ? ""
                                            : "No direct matches found."
                                        }
                                        results={assetList}
                                        value={assetSearch}
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={4}
                                      style={{
                                        lineHeight: "1",
                                        textAlign: "center",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      <Button
                                        type="button"
                                        className={
                                          formData?.makeId === 0 &&
                                          "active-status"
                                        }
                                        style={{
                                          fontSize: 12,
                                          lineHeight: "1.1",
                                          fontWeight: "bold",
                                          padding: "5px 5px",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        onClick={() =>
                                          handleChange(
                                            "makeId",
                                            formData?.makeId === 0 ? null : 0
                                          )
                                        }
                                      >
                                        Input Manually
                                      </Button>
                                    </Grid.Column>
                                  </Grid>
                                </Form.Field>
                                <Form.Field
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                  style={{
                                    opacity:
                                      formData?.makeId === 0 ? "1" : "0.7",
                                  }}
                                  required
                                >
                                  <label>Manufacturer</label>
                                  <Input
                                    className={
                                      formData?.makeName === "" && assetSaved
                                        ? "error"
                                        : ""
                                    }
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      pointerEvents:
                                        formData?.makeId === 0 ? "" : "none",
                                      cursor:
                                        formData?.makeId === 0
                                          ? "none"
                                          : "not-allowed",
                                    }}
                                    onChange={(e, data) =>
                                      handleChange("makeName", data.value)
                                    }
                                    value={formData?.makeName}
                                  />
                                </Form.Field>
                                <Form.Field
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                  style={{
                                    opacity:
                                      formData?.makeId === 0 ? "1" : "0.7",
                                  }}
                                  required
                                >
                                  <label>Equipment Type</label>
                                  <Input
                                    className={
                                      formData?.equipmentTypeName === "" &&
                                      assetSaved
                                        ? "error"
                                        : ""
                                    }
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      pointerEvents:
                                        formData?.makeId === 0 ? "" : "none",
                                      cursor:
                                        formData?.makeId === 0
                                          ? "none"
                                          : "not-allowed",
                                    }}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "equipmentTypeName",
                                        data.value
                                      )
                                    }
                                    value={formData?.equipmentTypeName}
                                  />
                                </Form.Field>
                                <Form.Field
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                  style={{
                                    opacity:
                                      formData?.makeId === 0 ? "1" : "0.7",
                                  }}
                                  required
                                >
                                  <label>Asset Model</label>
                                  <Input
                                    className={
                                      formData?.model === "" && assetSaved
                                        ? "error"
                                        : ""
                                    }
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      pointerEvents:
                                        formData?.makeId === 0 ? "" : "none",
                                      cursor:
                                        formData?.makeId === 0
                                          ? "none"
                                          : "not-allowed",
                                    }}
                                    onChange={(e, data) =>
                                      handleChange("model", data.value)
                                    }
                                    value={formData?.model}
                                  />
                                </Form.Field>
                                {formData?.id > 0 && !isEdit ? (
                                  <Form.Field>
                                    <label>Asset Hours/Mileage</label>
                                    <Grid columns={2} style={{ padding: 0 }}>
                                      <Grid.Column
                                        width={11}
                                        style={{
                                          paddingRight: 63,
                                          pointerEvents: "none",
                                        }}
                                      >
                                        <Input
                                          type="number"
                                          label={
                                            <Dropdown
                                              defaultValue="hrs"
                                              value={formData?.assetPropUnit}
                                              options={options}
                                              onChange={(e, data) =>
                                                handleChange(
                                                  "assetPropUnit",
                                                  data.value
                                                )
                                              }
                                            />
                                          }
                                          labelPosition="right"
                                          value={formData?.assetPropValue}
                                          onChange={(e, data) =>
                                            handleChange(
                                              "assetPropValue",
                                              data.value
                                            )
                                          }
                                        />
                                      </Grid.Column>
                                      <Grid.Column
                                        width={5}
                                        style={{
                                          lineHeight: "1",
                                          textAlign: "center",
                                          paddingLeft: 5,
                                        }}
                                      >
                                        <Button
                                          disabled={isLoading}
                                          type="button"
                                          style={{
                                            fontSize: 12,
                                            lineHeight: "1.1",
                                            fontWeight: "bold",
                                            padding: "5px 5px",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          onClick={() => {
                                            handleChange(
                                              "assetPropUnit",
                                              "hrs"
                                            );
                                            setOpenUpdateAssetHours(true);
                                          }}
                                        >
                                          Update <br /> Asset Hours
                                        </Button>
                                      </Grid.Column>
                                    </Grid>
                                  </Form.Field>
                                ) : (
                                  <Form.Field
                                    disabled={
                                      isLoading || (formData?.id > 0 && !isEdit)
                                    }
                                  >
                                    <label>Asset Hours/Mileage</label>
                                    <Input
                                      type="number"
                                      label={
                                        <Dropdown
                                          defaultValue="hrs"
                                          value={formData?.assetPropUnit}
                                          options={options}
                                          onChange={(e, data) =>
                                            handleChange(
                                              "assetPropUnit",
                                              data.value
                                            )
                                          }
                                        />
                                      }
                                      labelPosition="right"
                                      value={formData?.assetPropValue}
                                      onChange={(e, data) =>
                                        handleChange(
                                          "assetPropValue",
                                          data.value
                                        )
                                      }
                                    />
                                  </Form.Field>
                                )}
                              </Form>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              paddingLeft: 0,
                              paddingTop: 15,
                              paddingRight: 35,
                            }}
                          >
                            <Form size="tiny">
                              <Form.Field
                                disabled={
                                  isLoading || (formData?.id > 0 && !isEdit)
                                }
                              >
                                <label>Year</label>
                                <Input
                                  type="number"
                                  min={0}
                                  value={formData?.year}
                                  onChange={(e, data) =>
                                    handleChange("year", data.value)
                                  }
                                />
                              </Form.Field>
                              <Form.Field
                                required
                                disabled={
                                  isLoading || (formData?.id > 0 && !isEdit)
                                }
                              >
                                <label>Industry</label>
                                <Dropdown
                                  className={
                                    formData?.industry === "" && assetSaved
                                      ? "error"
                                      : ""
                                  }
                                  placeholder=""
                                  selection
                                  options={industryList}
                                  value={formData?.industry}
                                  onChange={(e, data) =>
                                    handleChange("industry", data.value)
                                  }
                                />
                              </Form.Field>
                              {formData?.industry === "Other" ? (
                                <Form.Field
                                  disabled={
                                    isLoading || (formData?.id > 0 && !isEdit)
                                  }
                                >
                                  <label>Industry (Other)</label>
                                  <Input
                                    value={formData?.industryOther}
                                    onChange={(e, data) =>
                                      handleChange("industryOther", data.value)
                                    }
                                  />
                                </Form.Field>
                              ) : null}
                              <Form.Field
                                disabled={
                                  isLoading || (formData?.id > 0 && !isEdit)
                                }
                              >
                                <label>Operator</label>
                                <Dropdown
                                  search
                                  placeholder=""
                                  selection
                                  options={operatorList}
                                  value={formData?.operator}
                                  onChange={(e, data) =>
                                    handleChange("operator", data.value)
                                  }
                                />
                              </Form.Field>
                              <Form.Field
                                disabled={
                                  isLoading || (formData?.id > 0 && !isEdit)
                                }
                              >
                                <label>Location</label>
                                <Dropdown
                                  placeholder=""
                                  selection
                                  options={assetLocationsList}
                                  value={formData?.assetLocationId}
                                  onChange={(e, data) =>
                                    handleChange("assetLocationId", data.value)
                                  }
                                />
                              </Form.Field>
                              <Form.Field
                                disabled={
                                  isLoading || (formData?.id > 0 && !isEdit)
                                }
                              >
                                <label>NON - BOA Service Parts</label>
                                <Input
                                  value={formData?.nonBoaServiceParts}
                                  onChange={(e, data) =>
                                    handleChange(
                                      "nonBoaServiceParts",
                                      data.value
                                    )
                                  }
                                />
                              </Form.Field>
                              <Form.Field
                                disabled={
                                  isLoading || (formData?.id > 0 && !isEdit)
                                }
                              >
                                <label>Connected Assets</label>
                                <Dropdown
                                  placeholder=""
                                  search
                                  multiple
                                  selection
                                  options={assetsList}
                                  value={formData?.connectedAssetsIds}
                                  onChange={(e, data) =>
                                    handleChange(
                                      "connectedAssetsIds",
                                      data.value
                                    )
                                  }
                                />
                              </Form.Field>
                              <Form.Field>
                                <label>Connected Asset IDs</label>
                                <Grid
                                  style={{
                                    paddingRight: 8,
                                    paddingLeft: "1rem",
                                    paddingTop: 8,
                                  }}
                                >
                                  {connectedIds.map((data) => {
                                    return (
                                      <Grid.Column
                                        width={16}
                                        style={{
                                          padding: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                        }}
                                      >
                                        <Card
                                          key={data.Id}
                                          style={{
                                            cursor: "pointer",
                                            width: "100%",
                                          }}
                                        >
                                          <Card.Content
                                            style={{
                                              minHeight: 50,
                                              padding: 20,
                                            }}
                                          >
                                            <Grid>
                                              <Grid.Column
                                                width={3}
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                  const history = assetHistory;
                                                  history.push(formData.id);
                                                  setAssetHistory(history);
                                                  setSelectedId(data.Id);
                                                  setSelectedCard(data.Id);
                                                }}
                                              >
                                                <Card.Description
                                                  style={{
                                                    fontSize: 10,
                                                    padding: 0,
                                                    cursor: "pointer",
                                                    paddingLeft: 10,
                                                  }}
                                                >
                                                  <img
                                                    src={assetIcon}
                                                    style={{
                                                      width: 28,
                                                      height: 28,
                                                      marginBottom: -35,
                                                    }}
                                                  />
                                                </Card.Description>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={13}
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                  const history = assetHistory;
                                                  history.push(formData.id);
                                                  setAssetHistory(history);
                                                  setSelectedId(data.Id);
                                                  setSelectedCard(data.Id);
                                                }}
                                              >
                                                <Card.Description
                                                  style={{
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  {data.AssetId}
                                                </Card.Description>
                                                <Card.Description
                                                  style={{
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  {data.EquipmentType}
                                                </Card.Description>
                                                <Card.Description
                                                  style={{
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  {data.Model}
                                                </Card.Description>
                                              </Grid.Column>
                                            </Grid>
                                          </Card.Content>
                                        </Card>
                                      </Grid.Column>
                                    );
                                  })}
                                </Grid>
                              </Form.Field>
                            </Form>
                          </Grid.Column>
                        </Grid>
                      </div>
                    </Form>
                  ) : viewRS23 ? (
                    <RS23 assetId={selectedId} userType={userType} />
                  ) : (
                    <Form size="tiny">
                      <Grid>
                        <Grid.Row
                          style={{ paddingBottom: 0 }}
                          disabled={isLoading || podsLoading || myPodsLoading}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 230,
                              left: 280,
                            }}
                          >
                            {podsLoading || myPodsLoading ? (
                              <Loader
                                active
                                className="workaround"
                                size="large"
                                inline="centered"
                              >
                                Loading BOApods...
                              </Loader>
                            ) : null}
                            {isLoading ? (
                              <Loader
                                active
                                className="workaround"
                                size="large"
                                inline="centered"
                                style={{ marginLeft: 20 }}
                              />
                            ) : null}
                          </div>
                          <Grid.Column
                            computer={1}
                            tablet={2}
                            mobile={2}
                            style={{
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              paddingRight: 0,
                            }}
                          >
                            <ImageContainer
                              width={18}
                              src={rightArrow}
                              wrapped
                              style={{ marginTop: -8.5 }}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={15}
                            tablet={14}
                            mobile={14}
                            style={{
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                            }}
                          >
                            <Header as="h5" style={{ marginLeft: -15 }}>
                              Job Request
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <div className="job-list-scroll" style={{ height: 650 }}>
                        <Grid>
                          <Grid.Row
                            style={{ paddingBottom: 0 }}
                            disabled={isLoading || podsLoading || myPodsLoading}
                          >
                            <Grid.Column width={8} style={{ paddingTop: 15 }}>
                              <div>
                                <Form size="tiny" onSubmit={handleSubmit}>
                                  <Form.Field required>
                                    <label>BOApod</label>
                                    <Dropdown
                                      noResultsMessage="No results found"
                                      className="boapod-dropdown"
                                      placeholder=""
                                      search
                                      selection
                                      options={podListData}
                                      value={bookData?.connectedAssetsIds}
                                      onChange={(e, data) =>
                                        handleBookChange("boapod", data.value)
                                      }
                                      style={{
                                        fontSize: 12,
                                        paddingTop: 11.5,
                                        paddingBottom: 11.5,
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <label>Location</label>
                                    <Autocomplete
                                      ref={locationRef}
                                      style={{ width: "100%" }}
                                      placeholder=""
                                      onPlaceSelected={(place, inputRef) => {
                                        onAddressSelection(place);
                                      }}
                                      types={["geocode"]}
                                      componentRestrictions={{
                                        country: countries,
                                      }}
                                      value={bookData?.customerLocation}
                                      onChange={(e, data) =>
                                        handleBookChange(
                                          "customerLocation",
                                          data
                                        )
                                      }
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <label>Purchase Order #</label>
                                    <Input
                                      value={
                                        bookData?.customerPurchaseOrderNumber
                                      }
                                      onChange={(e, data) =>
                                        handleBookChange(
                                          "customerPurchaseOrderNumber",
                                          data.value
                                        )
                                      }
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <label>Urgency</label>
                                    <Select
                                      options={urgenciesData}
                                      value={bookData?.urgencyId}
                                      onChange={(e, data) =>
                                        handleBookChange(
                                          "urgencyId",
                                          data.value
                                        )
                                      }
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    <label>Job Description</label>
                                    <TextArea
                                      rows={7}
                                      onChange={(e, data) =>
                                        handleBookChange("jobDesc", data.value)
                                      }
                                      value={bookData?.jobDesc}
                                    />
                                  </Form.Field>
                                </Form>
                              </div>
                            </Grid.Column>
                            <Grid.Column
                              width={8}
                              style={{
                                paddingLeft: 0,
                                paddingTop: 15,
                                paddingRight: 35,
                              }}
                            >
                              <div>
                                <Form size="tiny">
                                  <Form.Field>
                                    <label>Asset Hours/Mileage</label>
                                    <Input
                                      type="number"
                                      label={
                                        <Dropdown
                                          defaultValue="hrs"
                                          value={bookData?.assetPropUnit}
                                          options={options}
                                          onChange={(e, data) =>
                                            handleBookChange(
                                              "assetPropUnit",
                                              data.value
                                            )
                                          }
                                        />
                                      }
                                      labelPosition="right"
                                      value={bookData?.assetPropValue}
                                      onChange={(e, data) =>
                                        handleBookChange(
                                          "assetPropValue",
                                          data.value
                                        )
                                      }
                                    />
                                  </Form.Field>
                                </Form>
                              </div>
                              <div
                                style={{
                                  height: 407,
                                  width: "100%",
                                  paddingTop: 20,
                                }}
                              >
                                <GoogleMapReact
                                  bootstrapURLKeys={{ key: "" }}
                                  defaultCenter={{
                                    lat: -42.312144,
                                    lng: 172.474153,
                                  }}
                                  defaultZoom={5}
                                  zoom={hasLocation ? 11 : 5}
                                  center={
                                    hasLocation
                                      ? location
                                      : {
                                          lat: -42.312144,
                                          lng: 172.474153,
                                        }
                                  }
                                ></GoogleMapReact>
                              </div>
                            </Grid.Column>
                            <div
                              style={{
                                margin: "1rem",
                                width: "100%",
                                paddingRight: "1rem",
                              }}
                            >
                              <JobImageUploader setImages={getImages} />
                            </div>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            ) : dataLoading ? (
              <Skeleton count={30} />
            ) : null}
          </Grid.Column>
          {viewRS23 ? null : (
            <Grid.Column computer={4} tablet={16} mobile={16}>
              {selectedData && !bookAPod ? (
                <div className="job-info">
                  <Grid style={{ marginTop: 0 }}>
                    <Grid.Row
                      style={{
                        paddingTop: 80,
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <div
                        style={{ position: "absolute", bottom: 60, left: 140 }}
                      >
                        {imageLoading ? (
                          <Loader
                            active
                            className="workaround"
                            size="large"
                            inline="centered"
                          />
                        ) : null}
                      </div>
                      <Grid.Column
                        width={8}
                        style={{ textAlign: "left", marginBottom: 0 }}
                      >
                        <label style={{ color: "#66CB23" }}>
                          Asset Schematic
                        </label>
                        <div
                          className="ui image"
                          style={{ height: 150, width: 150, marginTop: 10 }}
                        >
                          {formData?.makeId !== 0 && (
                            <div
                              style={{
                                paddingTop: imageLoading ? 30 : 0,
                                marginLeft: imageLoading ? -20 : 0,
                              }}
                            >
                              {/* {imageLoading ? (
                              <Loader
                                active
                                className="workaround"
                                size="medium"
                                inline="centered"
                              />
                            ) : null} */}
                              <img
                                src={assetImage}
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        style={{ textAlign: "left", marginBottom: 0 }}
                      >
                        <label style={{ color: "#66CB23", marginLeft: 10 }}>
                          Asset Image
                        </label>
                        <div
                          className="ui image"
                          style={{ height: 150, width: 150, marginTop: 10 }}
                        >
                          {imageLoading ? null : formData.assetImageUrl &&
                            formData.assetImageUrl !== "" &&
                            !imageRemoved ? (
                            <>
                              <img
                                src={formData.assetImageUrl}
                                alt="Image Preview"
                                className="bomImage"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              />
                              <Button
                                disabled={formData?.id > 0 && !isEdit}
                                circular
                                icon="settings"
                                style={{
                                  position: "absolute",
                                  left: imageWidth ? imageWidth : 140,
                                  top: -12,
                                  cursor: "pointer",
                                  padding: "4px 9px 7px 9px",
                                }}
                                onClick={() => {
                                  setImageRemoved(true);
                                  setImageData(null);
                                  setImageSrc(null);
                                }}
                              >
                                x
                              </Button>
                            </>
                          ) : imageSrc ? (
                            <>
                              <img
                                src={`data:image/jpeg;base64,${imageSrc}`}
                                alt="Image Preview"
                                className="bomImage"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              />
                              <Button
                                disabled={formData?.id > 0 && !isEdit}
                                circular
                                icon="settings"
                                style={{
                                  position: "absolute",
                                  left: imageWidth ? imageWidth : 140,
                                  top: -12,
                                  cursor: "pointer",
                                  padding: "4px 9px 7px 9px",
                                }}
                                onClick={() => {
                                  setImageRemoved(true);
                                  setImageData(null);
                                  setImageSrc(null);
                                }}
                              >
                                x
                              </Button>
                            </>
                          ) : (
                            <AssetImageUploader
                              disabled={formData?.id > 0 && !isEdit}
                              setImage={getImage}
                              setImageSrc={getImageSrc}
                            />
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0 }}>
                      <Grid.Column
                        width={16}
                        style={{ textAlign: "left", marginBottom: 0 }}
                      >
                        <label style={{ color: "#66CB23" }}>
                          Additional Files
                        </label>
                        <Grid
                          style={{
                            paddingTop: 25,
                            marginBottom: 5,
                            paddingLeft: "1rem",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 60,
                              left: 100,
                            }}
                          >
                            {filesLoading ? (
                              <Loader
                                active
                                className="workaround"
                                size="large"
                                inline="centered"
                              >
                                Loading files...
                              </Loader>
                            ) : null}
                          </div>
                          {assetFiles.map((data) => {
                            return (
                              <Grid.Column
                                width={8}
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  paddingRight: 10,
                                  marginBottom: 10,
                                }}
                              >
                                <Card
                                  key={data.Id}
                                  style={{ cursor: "default" }}
                                >
                                  <Card.Content
                                    style={{
                                      minHeight: 50,
                                      padding: 10,
                                    }}
                                  >
                                    <Card.Description
                                      style={{
                                        fontSize: 10,
                                        padding: 0,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleDownload(data);
                                      }}
                                    >
                                      <Grid>
                                        <Grid.Column width={3}>
                                          <Icon name="file" />
                                        </Grid.Column>
                                        <Grid.Column
                                          width={12}
                                          style={{
                                            paddingLeft: 0,
                                            overflow: "auto",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {data.fileName
                                            ? data.fileName
                                            : data.name}
                                        </Grid.Column>
                                      </Grid>
                                    </Card.Description>
                                    <span
                                      style={{
                                        visibility:
                                          formData?.id > 0 && !isEdit
                                            ? "hidden"
                                            : "visible",
                                        position: "absolute",
                                        right: -6,
                                        top: -10,
                                        cursor: "pointer",
                                        padding: "4px 9px 7px 9px",
                                      }}
                                      onClick={() => {
                                        setSelectefFile(data);
                                        setDeleteFileOpen(true);
                                      }}
                                    >
                                      x
                                    </span>
                                  </Card.Content>
                                </Card>
                              </Grid.Column>
                            );
                          })}
                        </Grid>
                        <div
                          style={{ marginBottom: 10 }}
                          disabled={formData?.id > 0 && !isEdit}
                        >
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          <Button
                            disabled={filesLoading}
                            onClick={() => handleFileButtonClick()}
                            size="mini"
                          >
                            {filesLoading ? "Uploading..." : "Upload File"}
                          </Button>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          textAlign: "left",
                          marginTop: 10,
                          marginBottom: 0,
                        }}
                      >
                        <label style={{ color: "#66CB23" }}>
                          Asset Pre-start Checklist
                        </label>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          textAlign: "left",
                          marginBottom: 0,
                          height: 80,
                          marginTop: 10,
                        }}
                      >
                        <div
                          className="ui image"
                          style={{ height: 60, width: 155, marginTop: 3 }}
                        >
                          {imageLoading ? null : formData.assetPrestartChecklistUrl &&
                            formData.assetPrestartChecklistUrl !== "" &&
                            !fileRemoved ? (
                            <>
                              {fileNameIsImage(
                                formData.assetPrestartChecklistUrl
                              ) ? (
                                <>
                                  <img
                                    src={formData.assetPrestartChecklistUrl}
                                    alt="Image Preview"
                                    className="bomImage"
                                    style={{
                                      marginTop: "auto",
                                      marginBottom: "auto",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => openImageViewer()}
                                  />
                                  <Button
                                    disabled={formData?.id > 0 && !isEdit}
                                    circular
                                    icon="settings"
                                    style={{
                                      position: "absolute",
                                      left: 140,
                                      top: -12,
                                      cursor: "pointer",
                                      padding: "1px 6px 4px 6px",
                                    }}
                                    onClick={() => {
                                      setFileRemoved(true);
                                      setFileData(null);
                                      setFileSrc(null);
                                    }}
                                  >
                                    x
                                  </Button>
                                </>
                              ) : (
                                <Card
                                  style={{
                                    cursor: "default",
                                    minHeight: 60,
                                    maxHeight: 60,
                                    minWidth: 155,
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                                  }}
                                >
                                  <Card.Content
                                    style={{
                                      minHeight: 60,
                                      paddingLeft: 2,
                                      paddingTop: 10,
                                    }}
                                  >
                                    <Card.Description
                                      style={{
                                        fontSize: 11,
                                        padding: 0,
                                        cursor: "pointer",
                                        color: "#000",
                                      }}
                                      onClick={() => {
                                        handleDownloadFile(
                                          formData.assetPrestartChecklistUrl
                                        );
                                      }}
                                    >
                                      <Grid>
                                        <Grid.Column width={4}>
                                          <Icon
                                            name="file alternate outline"
                                            style={{ fontSize: 28 }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column
                                          width={10}
                                          style={{
                                            paddingTop: 20,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            overflow: "auto",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          Pre-start checklist
                                        </Grid.Column>
                                      </Grid>
                                    </Card.Description>
                                    <Button
                                      disabled={formData?.id > 0 && !isEdit}
                                      circular
                                      icon="close"
                                      style={{
                                        position: "absolute",
                                        left: imageWidth ? imageWidth : 120,
                                        top: 20,
                                        marginLeft: 10,
                                        cursor: "pointer",
                                        backgroundColor: "#fb2500",
                                        color: "#fff",
                                        fontSize: 10,
                                        padding: 4,
                                      }}
                                      onClick={() => {
                                        setFileRemoved(true);
                                        setFileData(null);
                                        setFileSrc(null);
                                      }}
                                    />
                                  </Card.Content>
                                </Card>
                              )}
                            </>
                          ) : fileData && fileIsImage(fileData) ? (
                            <>
                              <img
                                src={`data:image/jpeg;base64,${fileSrc}`}
                                alt="Image Previewer"
                                className="bomImage"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  cursor: "pointer",
                                }}
                                onClick={() => openImageViewer()}
                              />
                              <Button
                                disabled={formData?.id > 0 && !isEdit}
                                circular
                                icon="settings"
                                style={{
                                  position: "absolute",
                                  left: 140,
                                  top: -12,
                                  cursor: "pointer",
                                  padding: "1px 6px 4px 6px",
                                }}
                                onClick={() => {
                                  setFileRemoved(true);
                                  setFileData(null);
                                  setFileSrc(null);
                                }}
                              >
                                x
                              </Button>
                            </>
                          ) : fileData && !fileIsImage(fileData) ? (
                            <Card
                              style={{
                                cursor: "default",
                                minHeight: 60,
                                maxHeight: 60,
                                minWidth: 155,
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                              }}
                            >
                              <Card.Content
                                style={{
                                  minHeight: 60,
                                  paddingLeft: 2,
                                  paddingTop: 10,
                                }}
                              >
                                <Card.Description
                                  style={{
                                    fontSize: 11,
                                    padding: 0,
                                    cursor: "pointer",
                                    color: "#000",
                                  }}
                                  onClick={() => {
                                    handleDownloadLocal(fileData);
                                  }}
                                >
                                  <Grid>
                                    <Grid.Column width={4}>
                                      <Icon
                                        name="file alternate outline"
                                        style={{ fontSize: 28 }}
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={10}
                                      style={{
                                        paddingTop: 20,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        overflow: "auto",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      Pre-start checklist
                                    </Grid.Column>
                                  </Grid>
                                </Card.Description>
                                <Button
                                  disabled={formData?.id > 0 && !isEdit}
                                  circular
                                  icon="close"
                                  style={{
                                    position: "absolute",
                                    left: imageWidth ? imageWidth : 120,
                                    top: 20,
                                    marginLeft: 10,
                                    cursor: "pointer",
                                    backgroundColor: "#fb2500",
                                    color: "#fff",
                                    fontSize: 10,
                                    padding: 4,
                                  }}
                                  onClick={() => {
                                    setFileRemoved(true);
                                    setFileData(null);
                                    setFileSrc(null);
                                  }}
                                />
                              </Card.Content>
                            </Card>
                          ) : (
                            <AssetChecklistUploader
                              disabled={formData?.id > 0 && !isEdit}
                              setFile={getFile}
                              setFileSrc={getFileSrc}
                            />
                          )}
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        computer={2}
                        tablet={2}
                        mobile={2}
                        style={{
                          paddingTop: "1rem",
                          paddingBottom: 5,
                          paddingRight: 0,
                        }}
                      >
                        <div onClick={() => handleOpenHistory()}>
                          <ImageContainer
                            width={18}
                            src={rightArrow}
                            wrapped
                            style={{
                              width: 18,
                              marginTop: -8.5,
                              transform: `rotate(${
                                clicked ? "90deg" : "0deg"
                              })`,
                              transition: "transform 0.5s ease",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        computer={14}
                        tablet={14}
                        mobile={14}
                        style={{ paddingTop: "1rem", paddingBottom: 5 }}
                      >
                        <Header
                          as="h5"
                          style={{
                            marginLeft: -15,
                            marginBottom: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenHistory()}
                        >
                          Service History
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 0,
                        }}
                      >
                        <div
                          className="job-list"
                          style={{
                            height: clicked ? 295 : 0,
                            visibility: clicked ? "visible" : "hidden",
                          }}
                        >
                          <div ref={ref} className="job-list-scroll">
                            {serviceHistoriesGroup}
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        computer={2}
                        tablet={2}
                        mobile={2}
                        style={{
                          paddingTop: 30,
                          paddingBottom: "1rem",
                          paddingRight: 0,
                        }}
                      >
                        <ImageContainer
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -8.5 }}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={14}
                        tablet={14}
                        mobile={14}
                        style={{ paddingTop: 30, paddingBottom: "1rem" }}
                      >
                        <Header
                          as="h5"
                          style={{ marginLeft: -15, marginBottom: 5 }}
                        >
                          Oil & Filtration
                        </Header>
                        <span style={{ marginLeft: -15, fontSize: 13 }}>
                          Coming Soon
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              ) : dataLoading ? (
                <Skeleton count={30} />
              ) : null}
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default MyAssets;
